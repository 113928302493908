import React, { useState, useEffect, useRef } from "react";
import ProductDetails from "./ProductDetails";
import HowToUse from "./HowToUse";
import SizeCoverage from "./SizeCoverage";
import RelatedProducts from "./RelatedProducts";
import SectionFooter from "./SectionFooter";
import Modal from "./Modal";
import Footer from "../Footer";
import Menu from "./Menu";
import Header from "../Header";

// Style
import "../../styles/sections/second-page-main.scss";

const Secondpage = ({ animateElements, animateBlock }) => {
  // Modal
  const [isOpen, setIsOpen] = useState(false);
  const openModal = () => {
    setIsOpen(!isOpen);
  };

  // Tabs
  const [menuActive, setMenuActive] = useState(1);
  const changeMenuItem = (index) => {
    setMenuActive(index);
  };

  return (
    <>
      <section
        className={`contentArea ${
          animateElements == true ? "elementAnimate" : ""
        }`}
      >
        <div className="container">
          <div className="contentArea__wrap">
            <Header openModal={openModal} isOpen={isOpen} />
            <div
              className={`sectionBlock ${
                animateBlock == true ? "blockAnimate" : ""
              } `}
            >
              <Menu menuActive={menuActive} changeMenuItem={changeMenuItem} />
              <ProductDetails menuActive={menuActive} />
              <HowToUse menuActive={menuActive} />
              <SizeCoverage menuActive={menuActive} />
              <RelatedProducts menuActive={menuActive} />
              <SectionFooter menuActive={menuActive} />
              <Modal isOpen={isOpen} />
            </div>
            <Footer />
          </div>
        </div>
        <div className={`modal-overlay ${isOpen ? "active" : ""}`}></div>
      </section>
    </>
  );
};

export default Secondpage;

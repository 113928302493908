import React from "react";

const TopContent = ({title, subtitle}) => {
  return (
    <>
      <div className="topInfo">
        <div className="topInfo__wrap">
          <h2 className="title">{title}</h2>
          <span className="subtitle">{subtitle}</span>
        </div>
      </div>
    </>
  );
};

export default TopContent;

import React, { useState } from "react";
import { ReactSVG } from "react-svg";

// Styles
import '../../src/styles/header.scss';


const Header = ({ openModal, isOpen }) => {
  return (
    <>
      <div className="header">
        <div className="container">
          <div className="wrap">
            <div className="header__left header__item">
              <div className="header__logo">
                <img
                  className="header__logo_small"
                  src="../icons/logo-small.png"
                  alt="Logo"
                />
                <img
                  className="header__logo_big"
                  src="../icons/logo-big.png"
                  alt="Logo"
                />
              </div>
            </div>
            <div className="header__center header__item">
              <div className="header__menu">
                <a className="active" href="#">
                  <span>Products</span>
                </a>
                <a className="demo" href="#">
                  <span>About us</span>
                  <p>Demo</p>
                </a>
                <a className="demo" href="#">
                  <span>Support</span>
                  <p>Demo</p>
                </a>
                <a className="demo" href="#">
                  <span>Contact</span>
                  <p>Demo</p>
                </a>
              </div>
            </div>
            <div className="header__right header__item">
              <div className="hLinks">
                <a className="hLinks__item" href="#">
                  <div className="hLinks__item_icon">
                    <ReactSVG src="../icons/Dealer-login-resting.svg" />
                  </div>
                  <img
                    className="hLinks__item_info"
                    src="/icons/dealer-login.svg"
                    alt=""
                  />
                </a>
                <a className="hLinks__item" href="#">
                  <div className="hLinks__item_icon">
                    <ReactSVG src="../icons/Phone-resting.svg" />
                  </div>
                  <img
                    className="hLinks__item_info"
                    src="/icons/phone.svg"
                    alt=""
                  />
                </a>
                <a className="hLinks__item hLinks__item_last" href="#">
                  <div className="hLinks__item_icon">
                    <ReactSVG src="../icons/Store-locator-resting.svg" />
                  </div>
                  <img
                    className="hLinks__item_info"
                    src="/icons/location.svg"
                    alt=""
                  />
                </a>
                <div
                  onClick={openModal}
                  className={`hamburger ${isOpen ? "active" : ""}`}
                >
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;

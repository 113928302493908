import { create } from 'zustand'

export const useStore = create((set) => ({
//   loaderProgress: 0,
//   increasePopulation: () => set((state) => ({ bears: state.bears + 1 })),
//   removeAllBears: () => set({ bears: 0 }),
	start3DAnimation: false,
	amimation3dFinished: false,
	startFlareAnimaition: false,
	sound: true,
	perfActive: false
}))

// const handleResourceLoaded = (type, name) => {
// 	switch (type) {
// 		case 'model':
			
// 			break;
	
// 		default:
// 			break;
// 	}
// }

export const useLoaderStore = create((set) => ({
	showLoader: true,
	loaderProgress: 0,
	progress3D: 0,
	soundsProgress: 0,
	isSceneReady: false,
	handleResourceLoaded: (type, name) => {
		set((state) => {
		}

	)}
}))
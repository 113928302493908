import React from "react";

const BackgroundImage = ({ path }) => {
  return (
    <>
      <img className="sectionBGI" src={path} alt="Backround Image" />
    </>
  );
};

export default BackgroundImage;

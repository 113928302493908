import React, { useRef } from "react"
import { BlendFunction, Resizer, KernelSize } from 'postprocessing'
import {
  DepthOfField,
  EffectComposer,
  GodRays,
  SMAA,
  SSAO,
  Outline
} from '@react-three/postprocessing'
import { useControls } from 'leva'

const PostProcessing = () => {

  const sunRef = useRef()
  const outlineRef = useRef()
  const {
    color,
    samples,
    density,
    decay,
    weight,
    exposure,
    clampMax,
    sphere_radius,
    sunX, sunY, sunZ
  } = useControls('postprocessing', {
    color: '#dec496',
    // samples: name === 'Nav Menu GodRays' ? 60 : 40, // The number of samples per pixel.
    samples: 30, // The number of samples per pixel.
    density: {
      value: 0.96,
      min: 0,
      max: 1,
    }, // The density of the light rays.
    decay: {
      value: 0.92,
      min: 0,
      max: 1,
      step: 0.1,
    }, // An illumination decay factor.
    weight: {
      value: 0.3,
      min: 0,
      max: 1,
    }, // A light ray weight factor.
    exposure: {
      value: 0.54,
      min: 0,
      max: 1,
    }, // A constant attenuation coefficient.
    clampMax: {
      value: 1,
      min: 0,
      max: 1,
    },
    sphere_radius: 0.7,
    sunX: {
      value: 0.96,
      min: -20,
      max: 10,
    },
    sunY: {
      value: 3.00,
      min: -20,
      max: 10,
    },
    sunZ: {
      value: -10.00,
      min: -20,
      max: 10,
    },

  })
  return (
    <>
      <mesh ref={sunRef} 
        // position={[0.9,1.2,-10]}
        position={[sunX, sunY, sunZ]}
      >
        <sphereGeometry args={[0.5, 8, 8]} />
        <meshBasicMaterial color={color} opacity={1} />
      </mesh>
      {/* <mesh position={[1,2.5,-8]}>
        <sphereGeometry args={[0.3, 8, 8]} />
        <meshBasicMaterial color={'red'} opacity={1} />
      </mesh> */}

      {sunRef.current && (
          <EffectComposer multisampling={0}>
            <GodRays
              sun={sunRef.current}
              blendFunction={BlendFunction.SCREEN} // The blend function of this effect.
              samples={samples} // The number of samples per pixel.
              density={density} // The density of the light rays.
              decay={decay} // An illumination decay factor.
              weight={weight} // A light ray weight factor.
              exposure={exposure} // A constant attenuation coefficient.
              clampMax={clampMax} // An upper bound for the saturation of the overall effect.
              width={Resizer.AUTO_SIZE} // Render width.
              height={Resizer.AUTO_SIZE} // Render height.
              kernelSize={KernelSize.SMALL} // The blur kernel size. Has no effect if blur is disabled.
              // blur={1} // Whether the god rays should be blurred to reduce artifacts.
            />
            {/* <GodRays
              sun={sunRef.current}
              blendFunction={BlendFunction.SCREEN} // The blend function of this effect.
              samples={20} // The number of samples per pixel.
              density={0.96} // The density of the light rays.
              decay={0.9} // An illumination decay factor.
              weight={0.4} // A light ray weight factor.
              exposure={0.6} // A constant attenuation coefficient.
              clampMax={1} // An upper bound for the saturation of the overall effect.
              width={Resizer.AUTO_SIZE} // Render width.
              height={Resizer.AUTO_SIZE} // Render height.
              kernelSize={KernelSize.SMALL} // The blur kernel size. Has no effect if blur is disabled.
              blur={1} // Whether the god rays should be blurred to reduce artifacts.
            /> */}
            <SMAA />
            {/* {(appear && !isMobile && name !== 'Nav Menu GodRays') ? <SMAA /> : <></>} */}
            {/* <Outline
              xRay
              edgeStrength={2.5}
              pulseSpeed={0.0}
              visibleEdgeColor={0xffffff}
              hiddenEdgeColor={0x22090a}
              ref={outlineRef}
            /> */}
          </EffectComposer>
        )}
    </>
  )
}

export default PostProcessing
import React, { useState, useEffect, useRef } from "react";
import BackgroundImage from "./BackgroundImage";
import TopContent from "./TopContent";

// Styles
import "../../styles/sections/how-to-use.scss";

const HowToUse = ({ menuActive }) => {
  const beds = [
    {
      id: 1,
      image: "/images/howtouse/htu-1.png",
      desc: "Add 3 inches (7.5 cm) of Soil Conditioner into vegetable beds every spring.",
      advice: "Remember to always keep your plants thoroughly watered.",
    },
    {
      id: 2,
      image: "/images/howtouse/htu-2.png",
      desc: "Rejuvinate established plants by adding Soil Conditioner around the base of each plant.",
      advice: "Remember to always keep your plants thoroughly watered.",
    },
  ];

  const [activeTab, setActiveTab] = useState(1);

  const changeTab = (index) => {
    setActiveTab(index);
  };

  // Get Height
  const [heightParent, setHeightParent] = useState(0);
  const [heightChild, setHeightChild] = useState(0);

  const refParent = useRef(null);
  const refChild = useRef(null);

  useEffect(() => {
    setHeightParent(refParent.current.clientHeight);
    setHeightChild(refChild.current.clientHeight);
  });

  return (
    <>
      <div
        id="howToUse"
        className={`sBlock__item ${menuActive == 2 ? "active" : ""}`}
      >
        <BackgroundImage path="/images/backgrounds/Graphic2Bg.jpg" />
        <div className="sBlock__item_wrapper">
          <div className="sBlock__item_wrap blockForTabAnimate">
            <TopContent title="How to use" subtitle="Instructions" />
            <div className="howToUseContent blockHeight">
              <div className="tab">
                <div className="tabNav">
                  <div
                    className={`tabNav__item ${activeTab == 1 ? "active" : ""}`}
                    onClick={() => changeTab(1)}
                  >
                    <span>Rows or raised beds</span>
                  </div>
                  <div
                    className={`tabNav__item demo ${
                      activeTab == 2 ? "active" : ""
                    }`}
                  >
                    <span>IN-GROUND PLANTINGS</span>
                    <p>DEMO</p>
                  </div>
                </div>
                <div
                  ref={refParent}
                  className={`tabContent ${
                    heightChild >= heightParent ? "normalScrollElements" : ""
                  }`}
                >
                  <div ref={refChild} className="tabContent__wrap">
                    <div
                      className={`tabContent__item ${
                        activeTab == 1 ? "active" : ""
                      }`}
                    >
                      <div className="howToUse__wrap">
                        {beds.map((item) => {
                          return (
                            <div key={item.id} className="howToUse__item">
                              <div className="howToUse__item_icon">
                                <img src="/icons/leaf.png" alt="leaf" />
                              </div>
                              <img
                                className="funnel"
                                src="/images/howtouse/watering-can.png"
                                alt=""
                              />
                              <div className="howToUse__item_img">
                                <img src={item.image} alt="" />
                              </div>
                              <div className="howToUse__item_text">
                                <p className="howToUse__item-desc">
                                  {item.desc}
                                </p>
                                <p className="howToUse__item-advice">
                                  {item.advice}
                                </p>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    <div
                      className={`tabContent__item ${
                        activeTab == 2 ? "active" : ""
                      }`}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HowToUse;

import React, { useState, useEffect, useRef } from "react";
import { ReactSVG } from "react-svg";
import { EffectFade, Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import BackgroundImage from "./BackgroundImage";
import TopContent from "./TopContent";

// Styles
import "../../styles/sections/related-products.scss";

const RelatedProducts = ({ menuActive }) => {
  const products = [
    {
      id: 1,
      title: "Foxfarm® Original Planting Mix",
      subtitle: "Don't Treat Your Soil Like Dirt!®",
      desc: "Our Original Planting Mix is a blend of soil amendments such as aged forest products, sphagnum peat moss and earthworm castings. Use our Original Planting Mix for all your gardening projects.",
      imagePath: "/images/related-products/red-card.png",
      url: "/",
    },
    {
      id: 2,
      title: "Light Warrior® Seed Starter",
      subtitle: "Don't Treat Your Soil Like Dirt!®",
      desc: "Light Warrior® Seed Starter is designed to promote an environment beneficial to seed germination and root development. Use Light Warrior® Seed Starter for seed germination, cuttings and new plant starts for both indoor and outdoor cultivation.",
      imagePath: "/images/related-products/blue-card.png",
      url: "/",
    },
  ];

  const [addActive, setAddActive] = useState(false);

  const handleClick = () => {
    setAddActive(!addActive);
  };

  // Get Height
  const [heightParent, setHeightParent] = useState(0);
  const [heightChild, setHeightChild] = useState(0);

  const refParent = useRef(null);
  const refChild = useRef(null);

  useEffect(() => {
    setHeightParent(refParent.current.clientHeight);
    setHeightChild(refChild.current.clientHeight);
  });

  return (
    <>
      <div
        id="relatedProducts"
        className={`sBlock__item ${menuActive == 4 ? "active" : ""}`}
      >
        <BackgroundImage path="/images/backgrounds/Graphic4Bg.jpg" />
        <div className="sBlock__item_wrapper">
          <div className="sBlock__item_wrap blockForTabAnimate">
            <TopContent
              title="Related Products"
              subtitle="For all your gardening needs."
            />
            <div
              className={`rProductsBlock blockHeight ${
                addActive ? "active" : ""
              }`}
            >
              <div className="rProductsBlock__wrrapper">
                <div
                  ref={refParent}
                  className={`rProductsBlock__wrap scr_ver ${
                    heightChild >= heightParent ? "normalScrollElements" : ""
                  }`}
                >
                  <div ref={refChild} className="rProducts__list">
                    <Swiper
                      modules={[EffectFade, Navigation, Pagination]}
                      pagination={{
                        el: ".swiper-pagination",
                        type: "bullets",
                        clickable: true,
                      }}
                      navigation={{
                        nextEl: ".rProducts-next",
                        prevEl: ".rProducts-prev",
                      }}
                      spaceBetween={30}
                      slidesPerView={1}
                      effect="fade"
                      onSlideChange={handleClick}
                    >
                      {products.map((item) => {
                        return (
                          <SwiperSlide key={item.id}>
                            <div className="rProducts__item">
                              <h3 className="rProducts__item_title rProducts-mobile">
                                {item.title}
                              </h3>
                              <span className="rProducts__item_subtitle rProducts-mobile">
                                {item.subtitle}
                              </span>
                              <div className="rProducts__item_img">
                                <img
                                  className="rProducts__item_img"
                                  src={item.imagePath}
                                  alt="Image"
                                />
                              </div>
                              <div className="rProducts__item_content">
                                <div className="rProducts__item_content_text">
                                  <h3 className="rProducts__item_title">
                                    {item.title}
                                  </h3>
                                  <span className="rProducts__item_subtitle">
                                    {item.subtitle}
                                  </span>
                                  <p className="rProducts__item_desc">
                                    {item.desc}
                                  </p>
                                </div>
                                <a href={item.url} className="rProducts__btn">
                                  Explore Product
                                </a>
                              </div>
                            </div>
                          </SwiperSlide>
                        );
                      })}
                    </Swiper>
                    <div className="swiper-wrapper"></div>
                  </div>
                </div>
              </div>
              <div className="rProducts-next rProducts-arrow">
                <ReactSVG src="../icons/arrow.svg" />
              </div>
              <div className="rProducts-prev rProducts-arrow">
                <ReactSVG src="../icons/arrow.svg" />
              </div>
              <div className="swiper-pagination"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RelatedProducts;

import React, { useEffect, useState } from "react";
// import ReactDOM from "react-dom";
import Mainpage from "./components/Mainpage/Mainpage";
import SecondPage from "./components/Secondpage/Secondpage";
import ReactFullpage from "@fullpage/react-fullpage";

//Styles
import "../src/styles/base.scss";
import "../src/styles/layout.scss";
import Preloader from "./components/Preloader/Preloader";
import { useLoaderStore } from "./store";
import { audioManager } from "./js/helpers/AudioManager";
import { Howl, Howler } from 'howler'
import { useSpring } from "@react-spring/web";

function App() {
  const anchors = ["banner", "contentarea"];

  const [animateElements, setAnimateElements] = useState(false);
  const [animateBlock, setAnimateBlock] = useState(false);
  const disableScroll = useLoaderStore(state => state.showLoader)

  const [{ soundAlpha }, setSpring] = useSpring(() => ({
    soundAlpha: 1,
  }))

  const onLeave = (origin, destination, direction) => {
    if (origin.index === 0 && direction === "down") {
      setAnimateElements(true);
      setAnimateBlock(true);

      audioManager.fadeOutSounds()
      setSpring.start({
        soundAlpha: 0,
        config: {
          duration: 300,
        },
        onChange: {
          soundAlpha: (e) => {
            Howler.volume([e])
          },
        },
      })
    } else {
      setAnimateBlock(false);
      setSpring.start({
        soundAlpha: 1,
        config: {
          duration: 300,
        },
        onChange: {
          soundAlpha: (e) => {
            Howler.volume([e])
          },
        },
      })
    }
  };
  useEffect(() => {
    setTimeout(function() {
      fullpage_api && fullpage_api.setAllowScrolling(false); // eslint-disable-line
    }, 100);
    audioManager.loadSounds()
  }, [])

  useEffect(() => {
    if (!disableScroll) {
      fullpage_api && fullpage_api.setAllowScrolling(true); // eslint-disable-line
    }
  }, [disableScroll])

  return (
    <div className="App">
      <Preloader />
      <ReactFullpage
        anchors={anchors}
        licenseKey={"390281F7-78A54E36-A4D1D692-7A439190"}
        scrollingSpeed={500}
        onLeave={onLeave}
        normalScrollElements=".normalScrollElements"
        
        render={({ state, fullpageApi }) => {
          return (
            <ReactFullpage.Wrapper>
              <div className="section">
                <Mainpage />
              </div>
              <div className="section">
                <SecondPage
                  animateElements={animateElements}
                  animateBlock={animateBlock}
                />
              </div>
            </ReactFullpage.Wrapper>
          );
        }}
      />
    </div>
  );
}

export default App;

import React, { useState, useEffect } from "react";
import Header from "../Header";

// Styles
import "../../styles/sections/banner.scss";
import Container3D from "../components3d";
import { useStore } from "../../store";
import { ReactSVG } from "react-svg";
import { audioManager } from "../../js/helpers/AudioManager";

const Mainpage = () => {
  const [animateHeader, setAnimateHeader] = useState(false);
  const amimation3dFinished = useStore(state => state.amimation3dFinished)
  const sound = useStore(state => state.sound)

  const handleSoundClick = () => {
    audioManager.toggleSound()
  }

  useEffect(() => {
    amimation3dFinished && setAnimateHeader(true);
  }, [amimation3dFinished])

  return (
    <>
      <section className={`banner ${animateHeader ? "active" : ""}`}>
        <Header />

        {/* <div className="banner-bg">
          <img src="/images/main-bg.jpg" alt="" />
        </div> */}
        <Container3D />

        <div className="container" style={{
          pointerEvents: "none",
          // opacity:0
        }}>
          <div className="topInfo">
            <h2 className="title">
              Happy Frog<sup>®</sup> Soil Conditioner
            </h2>
            <span className="subtitle">
              In the Spirit of the Humboldt Nation
            </span>
          </div>
          <div className="sound-bttn" onClick={handleSoundClick}>
            <div>

            {sound ? <img src="../icons/sound-active.svg" /> : <img src="../icons/Sound Disabled.png" />}
            </div>
          </div>
        </div>
        <div className="header-texture">
          <img
            src="/images/Header Texture.png"
            alt=""/>
        </div>
      </section>
    </>
  );
};

export default Mainpage;

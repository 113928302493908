import React, { useState, useEffect, useRef } from "react";
import { ReactSVG } from "react-svg";
import BackgroundImage from "./BackgroundImage";

// Styles
import "../../styles/sections/section-footer.scss";

const SectionFooter = ({ menuActive}) => {


  // Get Height
  const [heightParent, setHeightParent] = useState(0);
  const [heightChild, setHeightChild] = useState(0);

  const refParent = useRef(null);
  const refChild = useRef(null);

  useEffect(() => {
    setHeightParent(refParent.current.clientHeight);
    setHeightChild(refChild.current.clientHeight);
  });

  return (
    <>
      <div
        id="sFooter"
        className={`sBlock__item sFooter ${
          menuActive == 5 ? "active" : ""
        } `}
      >
        <BackgroundImage path="/images/backgrounds/Graphic5Bg.jpg" />
        <img
          src="/images/section-footer/patetrn-top.png"
          className="sFooter__top_pattern"
          alt="Image"
        />
        <img
          src="/images/section-footer/pattern-bottom.png"
          className="sFooter__bottom_pattern"
          alt="Image"
        />

        <div className="sBlock__item_wrapper">
          <div className="sBlock__item_wrap blockForTabAnimate">
            <div ref={refParent} className={`wrap ${heightChild >= heightParent ? "normalScrollElements" : ""}`}>
              <div ref={refChild} className="sFooter-wrapper">
                <div className="sFooter__col">
                  <div className="sFooter__row">
                    <div className="sFooter__item">
                      <div className="sFooter__block">
                        <span className="sFooter__block_title">
                          Our Products
                        </span>
                        <div className="sFooter__menu">
                          <a href="#"> Soils & Soil Conditioners</a>
                          <a href="#"> Liquid Fertilizers</a>
                          <a href="#"> Dry Fertilizers</a>
                          <a href="#">Supplements, Adjusters & Inoculants</a>
                          <a href="#">Slow Release Fertilizers</a>
                          <a href="#">Soluble Fertilizers</a>
                          <a href="#">Pest Control</a>
                          <a href="#">Starter Packs</a>
                        </div>
                      </div>
                    </div>
                    <div className="sFooter__item">
                      <div className="sFooter__block">
                        <span className="sFooter__block_title">About</span>
                        <div className="sFooter__menu">
                          <a href="#">Our Company</a>
                          <a href="#">Owner's Message</a>
                          <a href="#">Careers</a>
                          <a href="#">Contact Us</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="sFooter__col">
                  <div className="sFooter__row">
                    <div className="sFooter__item">
                      <div className="sFooter__block">
                        <span className="sFooter__block_title">SUPPORT</span>
                        <div className="sFooter__menu">
                          <a href="#">FAQ</a>
                          <a href="#"> Growing Tips</a>
                          <a href="#"> Videos</a>
                          <a href="#"> Feeding Schedules</a>
                          <a href="#">Product Support</a>
                          <a href="#">Retailer Support</a>
                          <a href="#">General Questions</a>
                        </div>
                      </div>
                    </div>
                    <div className="sFooter__item">
                      <div className="sFooter__column">
                        <div className="sFooter__block storeLocation">
                          <span className="sFooter__block_title">
                            STORE LOCATOR
                          </span>
                          <img
                            src="/images/section-footer/usa-map.png"
                            alt="Map"
                          />
                          <span className="sFooter__block_zip">
                            Enter your
                            <p>zip</p>
                            code
                          </span>
                        </div>
                        <div className="sFooter__block notHover">
                          <div className="mapLeaf">
                            <img
                              src="/images/section-footer/maple-leaf.png"
                              alt="Leaf"
                            />
                            <span>View Our CANADIAN Products</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="socials">
              <a className="socials__item" href="#" target="_blank">
                <ReactSVG src="../icons/facebook.svg" />
              </a>
              <a className="socials__item" href="#" target="_blank">
                <ReactSVG src="../icons/instagram.svg" />
              </a>
              <a className="socials__item" href="#" target="_blank">
                <ReactSVG src="../icons/twitter.svg" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SectionFooter;

import React from "react";

const Modal = ({isOpen}) => {

  return (
    <>
      <div className={`contentArea__modal ${isOpen == true ? 'show' : ''}`}>
        <div className="contentArea__modal_scroll normalScrollElements scr_ver">
          <div className="contentArea__modal_wrap">
            <h2>WE HOPE YOU ENJOYED</h2>
            <span className="contentArea__modal_subtitle">
              THIS 3D WEBSITE DEMO.
            </span>
            <p>
              Using only the content available from your current Happy Frog®
              Soil & Conditioner webpage, our team of creative technologists at
              WebDisruptors.io curated an interactive 3D animated web
              experience, entirely unique to your brand and product.
            </p>

            <p>
              Drawing inspiration from your incredible product art at the onset
              of this project, our team embarked upon a great deal of internal
              debate as we deliberated over which product to focus on, for the
              purposes of this website demo.
            </p>

            <p>
              We sincerely hope that through this demo, you feel our (blind)
              effort to “hit the mark” in transcending the Happy Frog artwork
              from print to a digital medium was achieved. Our aim was to
              reproduce the same visceral reaction to your product artwork
              online, as is no doubt the experience felt by your customers
              offline, when first introduced to your product in stores.
            </p>

            <p>
              As our technology now allows us to bring our client’s 3D products
              to life online, we believe that although the digital medium is
              more commonly utilized as a business tool, a visually stunning,
              interactive and animated 3D experience can offer a visceral
              reaction to products, to deliver a substantial brand impact.
            </p>
            <p>
              We had a lot of fun exploring your brand and the Happy Frog® Soil
              & Conditioner product within an interactive medium. We would love
              to discuss the opportunity of bringing every FoxFarm product to
              life, as we are certain each product has a unique story to tell.
            </p>
            <p>
              Please feel free to connect with us a contact@webdisruptors.io
            </p>

            <p>
              Thank you, <br />
              The Web Disruptors team
            </p>
          </div>
        </div>
      </div>
    </>
  );
};


export default Modal;

import React, { useRef, useState, useEffect } from "react";
import BackgroundImage from "./BackgroundImage";
import TopContent from "./TopContent";

import { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

// Styles
import "../../styles/sections/product-details.scss";

const ProductDetails = ({
  menuActive,
}) => {
  const imageTitle = [
    {
      id: 1,
      title: "Earthworm Castings",
      image: "./images/product-details/earthworms.jpg",
    },
    {
      id: 2,
      title: "Bat Guano",
      image: "./images/product-details/bat-guano.jpg",
    },
    {
      id: 3,
      title: "Soil Microbes",
      image: "./images/product-details/soil-microbes.jpg",
    },
  ];

  // Get Height
  const [heightParent, setHeightParent] = useState(0);
  const [heightChild, setHeightChild] = useState(0);

  const refParent = useRef(null);
  const refChild = useRef(null);

  useEffect(() => {
    setHeightParent(refParent.current.clientHeight);
    setHeightChild(refChild.current.clientHeight);
  });

  return (
    <>
      <div
        id="productDetails"
        className={`sBlock__first sBlock__item ${
          menuActive == 1 ? "active" : ""
        }`}
      >
        <BackgroundImage path="/images/backgrounds/Graphic1Bg.jpg" />
        <div className="sBlock__item_wrapper">
          <div className="sBlock__item_wrap blockForTabAnimate">
            <TopContent
              title="Product details"
              subtitle="A SCIENTIFIC BLEND OF NATURE'S RICHES"
            />
            <div className="pDetailsBlock blockHeight ">
              <div
                ref={refParent}
                className={`pDetailsBlock__content scr_ver ${
                  heightChild >= heightParent ? "normalScrollElements" : ""
                }`}
              >
                <div ref={refChild} className="pDetailsBlock__content_wrap">
                  <span className="pDetailsBlock__title">
                    Don't Treat Your Soil Like Dirt! <sup>®</sup>
                  </span>
                  <p className="pDetailsBlock__subtitle">
                    Bring home a bale of our Happy Frog® Soil Conditioner, and
                    your plants will thank you.
                  </p>
                  <div className="pDetailsBlock__list">
                    <Swiper
                      modules={[Pagination]}
                      pagination={{
                        el: ".swiper-pagination-r",
                        type: "bullets",
                        clickable: true,
                      }}
                      breakpoints={{
                        768: {
                          slidesPerView: 3,
                          spaceBetween: 30,
                        },
                      }}
                      spaceBetween={30}
                      slidesPerView={1}
                    >
                      {imageTitle.map((item) => {
                        return (
                          <SwiperSlide key={item.id}>
                            <div className="pDetailsBlock__list_col">
                              <div className="pDetailsBlock__list_item">
                                <div className="pDetailsBlock__list_img">
                                  <img src={item.image} alt="" />
                                </div>
                                <span className="pDetailsBlock__list_title">
                                  {item.title}
                                </span>
                              </div>
                            </div>
                          </SwiperSlide>
                        );
                      })}
                    </Swiper>
                  </div>
                  <div className="swiper-pagination-r"></div>
                  <div className="pDetailsBlock__desc">
                    <p>
                      In every bag of Happy Frog® Soil Conditioner you'll find
                      finely-screened aged forest products,
                      <strong>earthworm castings,</strong>
                      and <strong>bat guano</strong>, not to mention soil
                      microbes to help increase root efficiency and encourage
                      nutrient uptake. We also add humic acids, which may
                      increase the uptake of important micronutrients.
                    </p>
                    <p>
                      For additional nutrition, use Happy Frog® Jump Start
                      Fertilizer for all new plantings, or the Happy Frog®
                      fertilizer that's right for your garden.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductDetails;

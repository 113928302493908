import React, { useEffect, useState, useRef, Suspense } from "react";
import { Canvas } from '@react-three/fiber'
import { Model as EnvScene} from './models/All_002'
import { Model as Frog} from './models/Frog_001'
import { Model as Boxes} from './models/Boxes'
import { CameraShake, Center, OrbitControls, Text3D, useVideoTexture } from "@react-three/drei";
import CameraControls from "./helpers/CameraControls";
import PostProcessing from "./helpers/Postprocessing";
import { ContactShadows, Environment, Float, Sky } from '@react-three/drei'
import * as THREE from 'three'
import { useLoaderStore, useStore } from "../../store";
import { useProgress } from "@react-three/drei"
import { Leva, useControls } from "leva";
import { Perf } from "r3f-perf";
import { isMobile } from "react-device-detect";
import Hotkeys from 'react-hot-keys';

const FLARE_IMAGES_COUNT = 20

const Loader = () => {
  const { progress } = useProgress()
  useEffect(() => {
    useLoaderStore.setState({ progress3D: progress })
  }, [progress])
}


const Container3D = () => {
  const frogModelRef = useRef();
  const envModelRef = useRef();

  const start3DAnimation = useStore(state => state.start3DAnimation)
  const [playAnimationFlag, setPlayAnimationFlag] = useState(false)
  const containerRef = useRef(null)

  const [flareCurrentImage, setFlareCurrentImage] = useState(0)
  const startFlareAnimaition = useStore(state => state.startFlareAnimaition)
  const perfActive = useStore((state) => state.perfActive)

  const {
    textX, textY, textZ
  } = useControls('Text Pos', {
    textX: {
      value: -1.1,
      min: -10,
      max: 10,
      step: 0.0001
    }, // The density of the light rays.
    textY: {
      value: 2.10,
      min: -10,
      max: 10,
      step: 0.0001
    },
    textZ: {
      value: -8.22,
      min: -10,
      max: 10,
      step: 0.0001
    }
  })
  useEffect(() => {
    if (start3DAnimation) {
      setPlayAnimationFlag(true)
    }
  }, [start3DAnimation])

  useEffect(() => {
    if (startFlareAnimaition) {
      setTimeout(() => {
        flareUpdateFunction(0)
        
      });
    }
  }, [startFlareAnimaition])

  const flareUpdateFunction = (n) => {
    if (n <= FLARE_IMAGES_COUNT) {
      setTimeout(() => {
        
        setFlareCurrentImage(n+1)
        flareUpdateFunction(n+1)
      }, 70);
    }
  }

  useEffect(() => {
    let n = 0
    while( n < FLARE_IMAGES_COUNT) {
      const img = new Image();
      img.src = `/images/flare_half_size_png/flare (${n + 1})-min.png`
      n++
    }
  }, [])

  return (
    <>
    <div className="flare-cont" style={{position: 'absolute', height: '100%'}}>
      <div className="flare-wrapper">
        <div id='flare' style={{
          // position: 'absolute'
        }}>
          <img
            src={`/images/flare_half_size_png/flare (${flareCurrentImage + 1})-min.png`}
            alt=""
          />
        </div>
      </div>
    </div>
    <div id="container3d" style={{
      // width: '100%',
      // height: '100%',
      
      position: 'absolute',
      width: '100%',
      height: '-webkit-fill-available'
    }}>
        <Hotkeys keyName="alt+g,option+g" 
        onKeyDown={() => useStore.setState({perfActive: !perfActive})}
        ></Hotkeys>
        <Canvas linear shadows >
          {perfActive && <Perf position={'top-left'}/>}
          <Suspense fallback={<Loader />}>
          <directionalLight position={[5, 10, 7.5]} castShadow intensity={1} shadow-camera-near={0.1}
            // shadow-camera-far={1}
            // shadow-camera-left={-10}
            // shadow-camera-right={10}
            // shadow-camera-top={1}
            // shadow-camera-bottom={-10}
            />
          {/* <mesh castShadow
            position={[0.8,1,-1]}
            // position={[sunX, sunY, sunZ]}
          >
            <pointLight />
            <sphereGeometry args={[0.5, 8, 8]} />
            <meshBasicMaterial color={'white'} opacity={1} />
          </mesh> */}
          {/* <mesh position={[0.8,0.2,-1]} rotation-x={- Math.PI / 2}>
            <planeBufferGeometry args={[1,1]} />
            
          </mesh>
          <ContactShadows position={[0.9,0.2,-1]} opacity={1} scale={10} far={1} /> */}
          {/* <mesh receiveShadow
            position={[1, 0.1, -1]}
            // position={[sunX, sunY, sunZ]}
          >
            <sphereGeometry args={[0.2, 8, 8]} />
            <meshBasicMaterial color={'white'} opacity={1} />
          </mesh> */}
            {/* <Center> */}
              {/* <Text3D
              position={[textX, textY, textZ]}
               size={0.2} font="fonts/Arial_Bold.json">
              Happy Frog ® Soil Conditioner
                <meshStandardMaterial color="black" />
              </Text3D> */}

            {/* </Center> */}
          {/* <ambientLight intensity={0.1} />
          <directionalLight color="red" position={[0, 0, 5]} />
          <mesh>
            <boxGeometry />
            <meshStandardMaterial />
          </mesh> */}
          {/* <OrbitControls target={[0.903, 0, 4.162]}/>  */}
          <CameraControls containerRef={containerRef} start3DAnimation={start3DAnimation}/>
          <ambientLight intensity={0.9} />
          {/* <directionalLight position={[0, 0, 5]} castShadow/> */}
          {/* <CameraShake maxYaw={0} maxPitch={0.01} maxRoll={0.01} yawFrequency={0.1} pitchFrequency={0.1} rollFrequency={0.1} /> */}
          <group
            ref={containerRef}
          // position-x={-1} position-y={-0.8} position-z={-3.5}
          >
            <EnvScene modelRef={envModelRef} playAnimationFlag={playAnimationFlag}/>
            <Frog modelRef={frogModelRef} playAnimationFlag={playAnimationFlag}/>
            <Boxes playAnimationFlag={playAnimationFlag}/>
          </group>
          {/* <Environment preset='warehouse' /> */}
          {!isMobile ? <PostProcessing /> : null}
          </Suspense>
        </Canvas>
        <Leva
          hidden // default = false, when true the GUI is hidden
        />
    </div>
    </>
  )
}

export default Container3D
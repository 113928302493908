import React, { useState } from "react";
import { ReactSVG } from "react-svg";

// import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
// import "react-tabs/style/react-tabs.css";

import BackgroundImage from "./BackgroundImage";
import TopContent from "./TopContent";

import "../../styles/sections/available-sizes.scss";

const coverageTable = [
  {
    id: 1,
    tabNavItem: "../images/aviable-sizes/1.5ft.3-Resting.svg",
    shortText: "1.5 cubic foot bag………. 3 ft. x 3 ft. x 1 in. deep",
    image: "../images/aviable-sizes/1.5ft.3-Graphics.png",
  },
  {
    id: 2,
    tabNavItem: "../images/aviable-sizes/3ft.3-Resting.svg",
    shortText: "3 cubic foot bag…………. 6 ft. x 6 ft. x 1 in. deep",
    image: "../images/aviable-sizes/3ft.3-Graphic.png",
  },
  {
    id: 3,
    tabNavItem: "../images/aviable-sizes/27ft.3-Resting.svg",
    shortText: "27 cubic foot tote……….. 18 ft. x 18 ft. x 1 in. deep",
    image: "../images/aviable-sizes/27ft.3-Graphic.png",
  },
  {
    id: 4,
    tabNavItem: "../images/aviable-sizes/55ft.3-Resting.svg",
    shortText: "55 cubic foot tote……. 25.5 ft. x 25.5 ft. x 1 in. deep",
    image: "../images/aviable-sizes/55ft.3-Graphic.png",
  },
];

const SizeCoverage = ({ menuActive }) => {
  const [activeTab, setActiveTab] = useState(1);

  const changeTab = (index) => {
    setActiveTab(index);
  };

  return (
    <>
      <div
        id="sizeCoverage"
        className={`sBlock__item ${
          menuActive === 3 ? "active" : ""
        }`}
      >
        <BackgroundImage path="/images/backgrounds/Graphic3Bg.jpg" />
        <div className="sBlock__item_wrapper">
          <div className="sBlock__item_wrap blockForTabAnimate">
            <TopContent title="Available Sizes" subtitle="& Coverage Tables" />

            <div className="aSizesTabNav">
              {coverageTable.map((item) => {
                return (
                  <div
                    key={item.id}
                    className={`aSizesTabNav__item ${
                      activeTab === item.id ? "active" : ""
                    }`}
                    data-id="first-tab"
                    onClick={() => changeTab(item.id)}
                  >
                    <ReactSVG src={item.tabNavItem} />
                  </div>
                );
              })}
            </div>

            <div className="aSizes__info">
              {coverageTable.map((item) => {
                return (
                  <div
                    key={item.id}
                    className={`aSizes__info_item ${
                      activeTab === item.id ? "active" : ""
                    }`}
                  >
                    <div className="aSizes__info_wrap">
                      <strong>Coverage Table</strong>
                      <img src="/icons/leaf.png" alt="leaf" />
                      {item.shortText}
                    </div>
                  </div>
                );
              })}
            </div>

            <div className="aSizesTabContent">
              {coverageTable.map((item) => {
                return (
                  <img
                    key={item.id}
                    className={`aSizesTabContent__item ${
                      activeTab === item.id ? "active" : ""
                    }`}
                    src={item.image}
                    alt="Graphics"
                  />
                );
              })}
            </div>

            <img
              className="grass"
              src="/images/aviable-sizes/GrassFlowers-Foreground.png"
              alt="Grass"
            />
            <img
              className="mobile-grass"
              src="/images/aviable-sizes/mobile-Grass.png"
              alt="Grass"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default SizeCoverage;

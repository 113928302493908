import React, { useState, useRef, useEffect } from "react";
import CameraEffects from "./CameraEffects";
import { OrbitControls, PerspectiveCamera } from '@react-three/drei'
import { useControls } from 'leva'
import * as THREE from 'three'
import { animated, easings, useSpring } from '@react-spring/three'

const curve = new THREE.CatmullRomCurve3( [
	// new THREE.Vector2( -10, 0 ),
	// new THREE.Vector2( -30, 5 ),
	// new THREE.Vector2( 0, 0 ),
  new THREE.Vector3( 0.903, 5, -5 ),
  new THREE.Vector3( 0.903, 3, -2.5 ),
  new THREE.Vector3( 0.903, 0.913, 4.162 )
] );

const points = curve.getPoints( 50 );
const geometry = new THREE.BufferGeometry().setFromPoints( points );

const material = new THREE.LineBasicMaterial( { color: 0xff0000 } );

// Create the final object to add to the scene
const splineObject = new THREE.Line( geometry, material );

const CameraControls = ({ containerRef, start3DAnimation }) => {
  const [camera, setCamera] = useState(null)
  const [secondCam, setSecondCam] = useState(null)
  const control = useRef(null)
  const cameraEffectsRef = useRef()
  const [controlsTarget, setControlsTarget] = useState([0, 0, 0])
  const [{ cameraAlpha }, setSpring] = useSpring(() => ({
    cameraAlpha: 0,
  }))
  const [cameraEffectsActive, setCameraEffects] = useState(false)
  const {
    camX,
    camY,
    camZ
  } = useControls('Camera Pos', {
    camX: {
      value: 0.0,
      min: -10,
      max: 10,
      step: 0.0001
    }, // The density of the light rays.
    camY: {
      value: 0.04,
      min: -10,
      max: 10,
      step: 0.0001
    },
    camZ: {
      value: 0.0,
      min: -10,
      max: 10,
      step: 0.0001
    }
  })

  useEffect(() => {
    if (containerRef.current && camera) {
      setSpring.start({
        cameraAlpha: 1,
        config: {
          duration: 4000, //2000,
          easing: easings.easeOutSine,
        },
        onRest: () => {
        },
        onResolve: () => setCameraEffects(true),
        onChange: {
          cameraAlpha: (e) => {
            const nextPoint = curve.getPoint(e)
            camera.position.copy(nextPoint)
          },
        },
      })
    }
  }, [start3DAnimation])

  useEffect(() => {
    if (camera && !start3DAnimation) {
      const nextPoint = curve.getPoint(0)
      camera.position.copy(nextPoint)

      if (window.innerHeight > window.innerWidth) {
        secondCam.fov = 25
        secondCam.updateProjectionMatrix()
      }
    }
  }, [camera])

  return (
    <>
    <mesh position={[0.903, 0.913, 4.162]}>
      <sphereBufferGeometry args={[0.3]}/>
    </mesh>
      <PerspectiveCamera
        name='main_camera'
        fov={25}
        far={1000}
        near={0.01}
        ref={setCamera}
        // position={[camX, camY, camZ]}
        position={[0.903, 0.913, 4.162]}
      >
        {/*@ts-ignore has all required props*/}
        <PerspectiveCamera
          fov={23}
          makeDefault
          far={300}
          near={0.01}
          ref={setSecondCam}
        />
      </PerspectiveCamera>
      {/* <OrbitControls 
        camera={camera}
        ref={control}
        target={[0.903, 0.2, 0]}
      /> */}

      {cameraEffectsActive ? <CameraEffects
        innerCamera={secondCam}
        ref={cameraEffectsRef}
        controls={control}
        camera={camera}
        setControlsTarget={setControlsTarget}
        helperMeshVisible={false}
        enable={true}
        rotationMultiplyX={0.07}
        rotationMultiplyY={0.07}
        // zoomEnabled={!isDragActive && !zoomBlocked}
      /> : null}
    </>
  )
}

export default CameraControls

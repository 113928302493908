import React from "react";

// Styles
import "../../styles/menu.scss";

const Menu = ({ changeMenuItem, menuActive }) => {
  return (
    <>
      <ul className="menu">
        <div className="menu__wrap">
          <li
            className={menuActive == 1 ? "active" : ""}
            onClick={() => changeMenuItem(1)}
          >
            <div className="line"></div>
            <span>
              Product <br />
              Details
            </span>
          </li>
          <li
            className={menuActive == 2 ? "active" : ""}
            onClick={() => changeMenuItem(2)}
          >
            <div className="line"></div>
            <span>
              How to <br />
              use
            </span>
          </li>
          <li
            className={menuActive == 3 ? "active" : ""}
            onClick={() => changeMenuItem(3)}
          >
            <div className="line"></div>
            <span>
              Size & <br />
              Coverage
            </span>
          </li>
          <li
            className={menuActive == 4 ? "active" : ""}
            onClick={() => changeMenuItem(4)}
          >
            <div className="line"></div>
            <span>
              Related <br />
              Products
            </span>
          </li>
          <li
            className={menuActive == 5 ? "active" : ""}
            onClick={() => changeMenuItem(5)}
          >
            <div className="line"></div>
            <span>Footer</span>
          </li>
        </div>
      </ul>
    </>
  );
};

export default Menu;

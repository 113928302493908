/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.3 all_002.glb
*/

import React, { useEffect, useRef } from 'react'
import { useGLTF, PerspectiveCamera, useAnimations, ContactShadows } from '@react-three/drei'

export function Model(props) {
  const { nodes, materials, animations } = useGLTF('/models/all_002.glb')
  const { actions } = useAnimations(animations, props.modelRef)

  useEffect(() => {
    props.playAnimationFlag && actions['All Animations'].play()
  }, [props.playAnimationFlag])
  useEffect(() => {
    // materials.blinn4.depthWrite = true
    // materials.blinn4.needsUpdate = true
    // materials.blinn2.depthWrite = true
    // materials.blinn2.needsUpdate = true

    // for (const [key, value] of Object.entries(materials)) {
    //   if (materials[key].depthWrite === false) {
    //     materials[key].depthWrite = true
    //   }
    // }
  }, [])
  return (
    <group ref={props.modelRef} dispose={null}>
      <group>
        <group name="Mesh1" position={[0.2, 0.39, -0.4]} rotation={[-2.6, -0.41, -3.04]} scale={1.84}>
          <group name="transform118" />
          <mesh name="polySurface339" geometry={nodes.polySurface339.geometry} material={materials.lambert2} rotation={[-0.01, 0.2, 0.46]} />
          <mesh name="polySurface340" geometry={nodes.polySurface340.geometry} material={materials.lambert2} rotation={[0, -0.2, -0.43]} />
          <mesh name="polySurface336" geometry={nodes.polySurface336.geometry} material={materials.blinn3} />
          <mesh name="polySurface337" geometry={nodes.polySurface337.geometry} material={materials.blinn3} />
          <mesh name="polySurface338" geometry={nodes.polySurface338.geometry} material={materials.blinn3} />
          <mesh name="polySurface341" geometry={nodes.polySurface341.geometry} material={materials.blinn3} />
          <mesh name="polySurface342" geometry={nodes.polySurface342.geometry} material={materials.blinn3} />
          <mesh name="polySurface343" geometry={nodes.polySurface343.geometry} material={materials.blinn3} />
          <mesh name="polySurface344" geometry={nodes.polySurface344.geometry} material={materials.blinn3} />
          <mesh name="polySurface345" geometry={nodes.polySurface345.geometry} material={materials.blinn3} />
          <mesh name="polySurface346" geometry={nodes.polySurface346.geometry} material={materials.blinn3} />
          <mesh name="polySurface347" geometry={nodes.polySurface347.geometry} material={materials.blinn3} />
          <mesh name="polySurface348" geometry={nodes.polySurface348.geometry} material={materials.blinn3} />
          <mesh name="polySurface349" geometry={nodes.polySurface349.geometry} material={materials.blinn3} />
          <mesh name="polySurface350" geometry={nodes.polySurface350.geometry} material={materials.blinn3} />
          <mesh name="polySurface351" geometry={nodes.polySurface351.geometry} material={materials.blinn3} />
          <mesh name="polySurface352" geometry={nodes.polySurface352.geometry} material={materials.blinn3} />
          <mesh name="polySurface353" geometry={nodes.polySurface353.geometry} material={materials.blinn3} />
          <mesh name="polySurface354" geometry={nodes.polySurface354.geometry} material={materials.blinn3} />
          <mesh name="polySurface355" geometry={nodes.polySurface355.geometry} material={materials.blinn3} />
          <mesh name="polySurface356" geometry={nodes.polySurface356.geometry} material={materials.blinn3} />
          <mesh name="polySurface357" geometry={nodes.polySurface357.geometry} material={materials.blinn3} />
          <mesh name="polySurface358" geometry={nodes.polySurface358.geometry} material={materials.blinn3} />
          <mesh name="polySurface359" geometry={nodes.polySurface359.geometry} material={materials.blinn3} />
          <mesh name="polySurface360" geometry={nodes.polySurface360.geometry} material={materials.blinn3} />
          <mesh name="polySurface361" geometry={nodes.polySurface361.geometry} material={materials.blinn3} />
          <mesh name="polySurface362" geometry={nodes.polySurface362.geometry} material={materials.blinn3} />
          <mesh name="polySurface363" geometry={nodes.polySurface363.geometry} material={materials.blinn3} />
          <mesh name="polySurface364" geometry={nodes.polySurface364.geometry} material={materials.blinn3} />
          <mesh name="polySurface365" geometry={nodes.polySurface365.geometry} material={materials.blinn3} />
          <mesh name="polySurface366" geometry={nodes.polySurface366.geometry} material={materials.blinn3} />
          <mesh name="polySurface367" geometry={nodes.polySurface367.geometry} material={materials.blinn3} />
          <mesh name="polySurface368" geometry={nodes.polySurface368.geometry} material={materials.blinn3} />
          <mesh name="polySurface369" geometry={nodes.polySurface369.geometry} material={materials.blinn3} />
          <mesh name="polySurface370" geometry={nodes.polySurface370.geometry} material={materials.blinn3} />
          <mesh name="polySurface371" geometry={nodes.polySurface371.geometry} material={materials.blinn3} />
          <mesh name="polySurface372" geometry={nodes.polySurface372.geometry} material={materials.blinn3} />
          <mesh name="polySurface373" geometry={nodes.polySurface373.geometry} material={materials.blinn3} />
        </group>
        <group name="vegie" position={[0, -0.05, 0.17]}>
          <group name="Tall_Grass1" position={[0.02, -0.08, -0.43]} rotation={[0.06, -0.12, 0.23]} scale={3.67}>
            <group name="transform4" />
            <mesh name="polySurface30" geometry={nodes.polySurface30.geometry} material={materials.follyedge} rotation={[-0.1, 0.09, -0.02]} />
            <mesh name="polySurface31" geometry={nodes.polySurface31.geometry} material={materials.follyedge} rotation={[0, 0, -0.11]} />
            <mesh name="polySurface32" geometry={nodes.polySurface32.geometry} material={materials.follyedge} position={[0.03, 0.01, 0]} rotation={[0, 0, -0.24]} />
            <mesh name="polySurface39" geometry={nodes.polySurface39.geometry} material={materials.follyedge} rotation={[0, 0, -0.11]} />
            <mesh name="polySurface40" geometry={nodes.polySurface40.geometry} material={materials.follyedge} position={[0, 0.01, 0]} rotation={[0, -0.97, -0.24]} />
            <mesh name="polySurface45" geometry={nodes.polySurface45.geometry} material={materials.follyedge} position={[0.02, 0, 0]} />
            <mesh name="polySurface46" geometry={nodes.polySurface46.geometry} material={materials.follyedge} position={[-0.02, 0, 0]} rotation={[-0.15, 0.12, -0.19]} />
            <mesh name="polySurface24" geometry={nodes.polySurface24.geometry} material={materials.follyedge} />
            <mesh name="polySurface25" geometry={nodes.polySurface25.geometry} material={materials.follyedge} />
            <mesh name="polySurface26" geometry={nodes.polySurface26.geometry} material={materials.follyedge} />
            <mesh name="polySurface27" geometry={nodes.polySurface27.geometry} material={materials.follyedge} />
            <mesh name="polySurface28" geometry={nodes.polySurface28.geometry} material={materials.follyedge} />
            <mesh name="polySurface29" geometry={nodes.polySurface29.geometry} material={materials.follyedge} />
            <mesh name="polySurface33" geometry={nodes.polySurface33.geometry} material={materials.follyedge} />
            <mesh name="polySurface34" geometry={nodes.polySurface34.geometry} material={materials.follyedge} />
            <mesh name="polySurface35" geometry={nodes.polySurface35.geometry} material={materials.follyedge} />
            <mesh name="polySurface36" geometry={nodes.polySurface36.geometry} material={materials.follyedge} />
            <mesh name="polySurface37" geometry={nodes.polySurface37.geometry} material={materials.follyedge} />
            <mesh name="polySurface38" geometry={nodes.polySurface38.geometry} material={materials.follyedge} />
            <mesh name="polySurface41" geometry={nodes.polySurface41.geometry} material={materials.follyedge} />
            <mesh name="polySurface42" geometry={nodes.polySurface42.geometry} material={materials.follyedge} />
            <mesh name="polySurface43" geometry={nodes.polySurface43.geometry} material={materials.follyedge} />
            <mesh name="polySurface44" geometry={nodes.polySurface44.geometry} material={materials.follyedge} />
            <mesh name="polySurface47" geometry={nodes.polySurface47.geometry} material={materials.follyedge} />
            <mesh name="polySurface48" geometry={nodes.polySurface48.geometry} material={materials.follyedge} />
            <mesh name="polySurface49" geometry={nodes.polySurface49.geometry} material={materials.follyedge} />
            <mesh name="polySurface50" geometry={nodes.polySurface50.geometry} material={materials.follyedge} />
          </group>
          <group name="Tall_Grass" position={[1.73, 0.04, 0]} rotation={[-Math.PI, 0, -3.04]} scale={[3.47, 3.47, -3.47]}>
            <group name="transform6" />
            <mesh name="polySurface165" geometry={nodes.polySurface165.geometry} material={materials.follyedge} rotation={[0.01, -0.03, -0.02]} />
            <mesh name="polySurface167" geometry={nodes.polySurface167.geometry} material={materials.follyedge} position={[0.01, 0.02, 0]} rotation={[0, 0, -0.22]} />
            <mesh name="polySurface175" geometry={nodes.polySurface175.geometry} material={materials.follyedge} rotation={[0, 0, -0.07]} />
            <mesh name="polySurface178" geometry={nodes.polySurface178.geometry} material={materials.follyedge} rotation={[0, 0, -0.07]} />
            <mesh name="polySurface159" geometry={nodes.polySurface159.geometry} material={materials.follyedge} />
            <mesh name="polySurface160" geometry={nodes.polySurface160.geometry} material={materials.follyedge} />
            <mesh name="polySurface161" geometry={nodes.polySurface161.geometry} material={materials.follyedge} />
            <mesh name="polySurface162" geometry={nodes.polySurface162.geometry} material={materials.follyedge} />
            <mesh name="polySurface163" geometry={nodes.polySurface163.geometry} material={materials.follyedge} />
            <mesh name="polySurface164" geometry={nodes.polySurface164.geometry} material={materials.follyedge} />
            <mesh name="polySurface166" geometry={nodes.polySurface166.geometry} material={materials.follyedge} />
            <mesh name="polySurface168" geometry={nodes.polySurface168.geometry} material={materials.follyedge} />
            <mesh name="polySurface169" geometry={nodes.polySurface169.geometry} material={materials.follyedge} />
            <mesh name="polySurface170" geometry={nodes.polySurface170.geometry} material={materials.follyedge} />
            <mesh name="polySurface171" geometry={nodes.polySurface171.geometry} material={materials.follyedge} />
            <mesh name="polySurface172" geometry={nodes.polySurface172.geometry} material={materials.follyedge} />
            <mesh name="polySurface173" geometry={nodes.polySurface173.geometry} material={materials.follyedge} />
            <mesh name="polySurface174" geometry={nodes.polySurface174.geometry} material={materials.follyedge} />
            <mesh name="polySurface176" geometry={nodes.polySurface176.geometry} material={materials.follyedge} />
            <mesh name="polySurface177" geometry={nodes.polySurface177.geometry} material={materials.follyedge} />
            <mesh name="polySurface179" geometry={nodes.polySurface179.geometry} material={materials.follyedge} />
            <mesh name="polySurface180" geometry={nodes.polySurface180.geometry} material={materials.follyedge} />
            <mesh name="polySurface181" geometry={nodes.polySurface181.geometry} material={materials.follyedge} />
            <mesh name="polySurface182" geometry={nodes.polySurface182.geometry} material={materials.follyedge} />
            <mesh name="polySurface183" geometry={nodes.polySurface183.geometry} material={materials.follyedge} />
            <mesh name="polySurface184" geometry={nodes.polySurface184.geometry} material={materials.follyedge} />
            <mesh name="polySurface185" geometry={nodes.polySurface185.geometry} material={materials.follyedge} />
          </group>
          <group name="Fern" position={[2.133642, 0.005312, -0.17]} rotation={[-Math.PI, 0, 2.983988]} scale={[3.355566, 3.355566, -3.355566]}>
            <group name="transform7" />
            <mesh name="polySurface186" geometry={nodes.polySurface186.geometry} material={materials.follyedge2} />
            <mesh name="polySurface187" geometry={nodes.polySurface187.geometry} material={materials.follyedge2} position={[0, 0, 0.002649]} />
            <mesh name="polySurface188" geometry={nodes.polySurface188.geometry} material={materials.follyedge2} />
            <mesh name="polySurface189" geometry={nodes.polySurface189.geometry} material={materials.follyedge2} />
            <mesh name="polySurface190" geometry={nodes.polySurface190.geometry} material={materials.follyedge2} position={[0, 0, 0.000616]} />
            <mesh name="polySurface191" geometry={nodes.polySurface191.geometry} material={materials.follyedge2} position={[0, 0, 0.001142]} />
            <mesh name="polySurface192" geometry={nodes.polySurface192.geometry} material={materials.follyedge2} position={[0, 0, 0.001746]} />
            <mesh name="polySurface193" geometry={nodes.polySurface193.geometry} material={materials.follyedge2} position={[-0.001209, -0.000192, 0.003011]} />
            <mesh name="polySurface194" geometry={nodes.polySurface194.geometry} material={materials.follyedge2} position={[-0.001756, -0.00235, 0.002234]} />
            <mesh name="polySurface195" geometry={nodes.polySurface195.geometry} material={materials.follyedge2} position={[0, 0, 0.001789]} />
            <mesh name="polySurface196" geometry={nodes.polySurface196.geometry} material={materials.follyedge2} position={[0, 0, 0.001666]} />
            <mesh name="polySurface197" geometry={nodes.polySurface197.geometry} material={materials.follyedge2} position={[0.000262, -0.001649, 0.002834]} />
            <mesh name="polySurface198" geometry={nodes.polySurface198.geometry} material={materials.follyedge2} position={[0.000262, -0.001649, 0.004106]} />
            <mesh name="polySurface199" geometry={nodes.polySurface199.geometry} material={materials.follyedge2} position={[0.000262, -0.001649, 0.004106]} />
            <mesh name="polySurface200" geometry={nodes.polySurface200.geometry} material={materials.follyedge2} position={[0.0003, -0.001888, 0.004043]} />
            <mesh name="polySurface201" geometry={nodes.polySurface201.geometry} material={materials.follyedge2} position={[0.000383, -0.002409, 0.003854]} />
            <mesh name="polySurface202" geometry={nodes.polySurface202.geometry} material={materials.follyedge2} position={[0.000169, -0.001062, 0.003534]} />
            <mesh name="polySurface203" geometry={nodes.polySurface203.geometry} material={materials.follyedge2} />
            <mesh name="polySurface204" geometry={nodes.polySurface204.geometry} material={materials.follyedge2} />
            <mesh name="polySurface205" geometry={nodes.polySurface205.geometry} material={materials.follyedge2} />
            <mesh name="polySurface206" geometry={nodes.polySurface206.geometry} material={materials.follyedge2} />
            <mesh name="polySurface207" geometry={nodes.polySurface207.geometry} material={materials.follyedge2} />
            <mesh name="polySurface208" geometry={nodes.polySurface208.geometry} material={materials.follyedge2} />
            <mesh name="polySurface209" geometry={nodes.polySurface209.geometry} material={materials.follyedge2} />
            <mesh name="polySurface210" geometry={nodes.polySurface210.geometry} material={materials.follyedge2} />
            <mesh name="polySurface211" geometry={nodes.polySurface211.geometry} material={materials.follyedge2} />
            <mesh name="polySurface212" geometry={nodes.polySurface212.geometry} material={materials.follyedge2} />
            <mesh name="polySurface213" geometry={nodes.polySurface213.geometry} material={materials.follyedge2} />
            <mesh name="polySurface214" geometry={nodes.polySurface214.geometry} material={materials.follyedge2} />
            <mesh name="polySurface215" geometry={nodes.polySurface215.geometry} material={materials.follyedge2} position={[-0.000202, -0.000032, 0.001916]} />
            <mesh name="polySurface216" geometry={nodes.polySurface216.geometry} material={materials.follyedge2} position={[-0.001154, -0.002879, -0.000154]} />
            <mesh name="polySurface217" geometry={nodes.polySurface217.geometry} material={materials.follyedge2} position={[-0.000202, -0.000032, 0.001916]} />
            <mesh name="polySurface218" geometry={nodes.polySurface218.geometry} material={materials.follyedge2} position={[-0.000202, -0.000032, 0.001916]} />
            <mesh name="polySurface219" geometry={nodes.polySurface219.geometry} material={materials.follyedge2} position={[-0.000202, -0.000032, 0.001916]} />
            <mesh name="polySurface220" geometry={nodes.polySurface220.geometry} material={materials.follyedge2} position={[-0.000202, -0.000032, 0.001916]} />
            <mesh name="polySurface221" geometry={nodes.polySurface221.geometry} material={materials.follyedge2} />
            <mesh name="polySurface222" geometry={nodes.polySurface222.geometry} material={materials.follyedge2} />
            <mesh name="polySurface223" geometry={nodes.polySurface223.geometry} material={materials.follyedge2} />
            <mesh name="polySurface224" geometry={nodes.polySurface224.geometry} material={materials.follyedge2} />
            <mesh name="polySurface225" geometry={nodes.polySurface225.geometry} material={materials.follyedge2} position={[0, 0, 0.001745]} />
            <mesh name="polySurface226" geometry={nodes.polySurface226.geometry} material={materials.follyedge2} position={[0, 0, 0.001745]} />
            <mesh name="polySurface227" geometry={nodes.polySurface227.geometry} material={materials.follyedge2} position={[0, 0, 0.001745]} />
            <mesh name="polySurface228" geometry={nodes.polySurface228.geometry} material={materials.follyedge2} position={[0, 0, 0.001745]} />
            <mesh name="polySurface229" geometry={nodes.polySurface229.geometry} material={materials.follyedge2} position={[0, 0, 0.003529]} />
            <mesh name="polySurface230" geometry={nodes.polySurface230.geometry} material={materials.follyedge2} position={[-0.000927, -0.000147, 0.002152]} />
            <mesh name="polySurface231" geometry={nodes.polySurface231.geometry} material={materials.follyedge2} position={[-0.000168, 0.001055, 0.001093]} />
            <mesh name="polySurface232" geometry={nodes.polySurface232.geometry} material={materials.follyedge2} position={[-0.002343, -0.000372, 0.001549]} />
            <mesh name="polySurface233" geometry={nodes.polySurface233.geometry} material={materials.follyedge2} position={[-0.00297, -0.000472, 0.003214]} />
            <mesh name="polySurface234" geometry={nodes.polySurface234.geometry} material={materials.follyedge2} position={[-0.002987, -0.000475, 0.003779]} />
            <mesh name="polySurface235" geometry={nodes.polySurface235.geometry} material={materials.follyedge2} position={[-0.00197, -0.000874, 0.002729]} />
            <mesh name="polySurface236" geometry={nodes.polySurface236.geometry} material={materials.follyedge2} position={[-0.000202, 0.00953, -0.003007]} />
            <mesh name="polySurface237" geometry={nodes.polySurface237.geometry} material={materials.follyedge2} position={[-0.001029, -0.011764, 0.007166]} />
            <mesh name="polySurface238" geometry={nodes.polySurface238.geometry} material={materials.follyedge2} />
            <mesh name="polySurface239" geometry={nodes.polySurface239.geometry} material={materials.follyedge2} />
            <mesh name="polySurface240" geometry={nodes.polySurface240.geometry} material={materials.follyedge2} />
            <mesh name="polySurface241" geometry={nodes.polySurface241.geometry} material={materials.follyedge2} />
            <mesh name="polySurface242" geometry={nodes.polySurface242.geometry} material={materials.follyedge2} />
            <mesh name="polySurface243" geometry={nodes.polySurface243.geometry} material={materials.follyedge2} />
            <mesh name="polySurface244" geometry={nodes.polySurface244.geometry} material={materials.follyedge2} />
            <mesh name="polySurface245" geometry={nodes.polySurface245.geometry} material={materials.follyedge2} />
            <mesh name="polySurface246" geometry={nodes.polySurface246.geometry} material={materials.follyedge2} />
            <mesh name="polySurface247" geometry={nodes.polySurface247.geometry} material={materials.follyedge2} />
            <mesh name="polySurface248" geometry={nodes.polySurface248.geometry} material={materials.follyedge2} />
            <mesh name="polySurface249" geometry={nodes.polySurface249.geometry} material={materials.follyedge2} />
            <mesh name="polySurface250" geometry={nodes.polySurface250.geometry} material={materials.follyedge2} />
            <mesh name="polySurface251" geometry={nodes.polySurface251.geometry} material={materials.follyedge2} position={[0, 0, 0.001501]} />
            <mesh name="polySurface252" geometry={nodes.polySurface252.geometry} material={materials.follyedge2} position={[0.000356, -0.002239, -0.000246]} />
            <mesh name="polySurface253" geometry={nodes.polySurface253.geometry} material={materials.follyedge2} position={[0, 0, 0.001551]} />
            <mesh name="polySurface254" geometry={nodes.polySurface254.geometry} material={materials.follyedge2} position={[0, 0, 0.001697]} />
            <mesh name="polySurface255" geometry={nodes.polySurface255.geometry} material={materials.follyedge2} position={[0, 0, 0.000811]} />
            <mesh name="polySurface256" geometry={nodes.polySurface256.geometry} material={materials.follyedge2} />
            <mesh name="polySurface257" geometry={nodes.polySurface257.geometry} material={materials.follyedge2} />
            <mesh name="polySurface258" geometry={nodes.polySurface258.geometry} material={materials.follyedge2} />
            <mesh name="polySurface259" geometry={nodes.polySurface259.geometry} material={materials.follyedge2} />
            <mesh name="polySurface260" geometry={nodes.polySurface260.geometry} material={materials.follyedge2} />
            <mesh name="polySurface261" geometry={nodes.polySurface261.geometry} material={materials.follyedge2} />
            <mesh name="polySurface262" geometry={nodes.polySurface262.geometry} material={materials.follyedge2} />
            <mesh name="polySurface263" geometry={nodes.polySurface263.geometry} material={materials.follyedge2} />
            <mesh name="polySurface264" geometry={nodes.polySurface264.geometry} material={materials.follyedge2} />
            <mesh name="polySurface265" geometry={nodes.polySurface265.geometry} material={materials.follyedge2} position={[-0.00052, 0.003271, 0.000281]} />
            <mesh name="polySurface266" geometry={nodes.polySurface266.geometry} material={materials.follyedge2} position={[-0.00052, 0.003271, 0.000281]} />
            <mesh name="polySurface267" geometry={nodes.polySurface267.geometry} material={materials.follyedge2} position={[-0.00052, 0.003271, 0.000281]} />
            <mesh name="polySurface268" geometry={nodes.polySurface268.geometry} material={materials.follyedge2} position={[-0.00052, 0.003271, 0.000281]} />
            <mesh name="polySurface269" geometry={nodes.polySurface269.geometry} material={materials.follyedge2} position={[-0.002253, 0.001763, 0.00208]} />
            <mesh name="polySurface270" geometry={nodes.polySurface270.geometry} material={materials.follyedge2} position={[-0.002253, 0.001763, 0.00208]} />
            <mesh name="polySurface271" geometry={nodes.polySurface271.geometry} material={materials.follyedge2} position={[-0.002253, 0.001763, 0.00208]} />
            <mesh name="polySurface272" geometry={nodes.polySurface272.geometry} material={materials.follyedge2} position={[-0.002253, 0.001763, 0.00208]} />
            <mesh name="polySurface273" geometry={nodes.polySurface273.geometry} material={materials.follyedge2} position={[-0.002253, 0.001763, 0.00208]} />
            <mesh name="polySurface274" geometry={nodes.polySurface274.geometry} material={materials.follyedge2} position={[-0.002253, 0.001763, 0.00208]} />
            <mesh name="polySurface275" geometry={nodes.polySurface275.geometry} material={materials.follyedge2} position={[-0.002253, 0.001763, 0.00208]} />
            <mesh name="polySurface276" geometry={nodes.polySurface276.geometry} material={materials.follyedge2} />
            <mesh name="polySurface277" geometry={nodes.polySurface277.geometry} material={materials.follyedge2} />
            <mesh name="polySurface278" geometry={nodes.polySurface278.geometry} material={materials.follyedge2} />
            <mesh name="polySurface279" geometry={nodes.polySurface279.geometry} material={materials.follyedge2} position={[0, 0, 0.00232]} />
            <mesh name="polySurface280" geometry={nodes.polySurface280.geometry} material={materials.follyedge2} position={[0, 0, 0.002407]} />
            <mesh name="polySurface281" geometry={nodes.polySurface281.geometry} material={materials.follyedge2} position={[0, 0, 0.002025]} />
            <mesh name="polySurface282" geometry={nodes.polySurface282.geometry} material={materials.follyedge2} position={[0, 0, 0.003412]} />
            <mesh name="polySurface283" geometry={nodes.polySurface283.geometry} material={materials.follyedge2} position={[0, 0, 0.000983]} />
            <mesh name="polySurface284" geometry={nodes.polySurface284.geometry} material={materials.follyedge2} position={[0, 0, 0.000983]} />
            <mesh name="polySurface285" geometry={nodes.polySurface285.geometry} material={materials.follyedge2} position={[0, 0, 0.000983]} />
            <mesh name="polySurface286" geometry={nodes.polySurface286.geometry} material={materials.follyedge2} />
            <mesh name="polySurface287" geometry={nodes.polySurface287.geometry} material={materials.follyedge2} position={[0, 0, 0.001706]} />
            <mesh name="polySurface288" geometry={nodes.polySurface288.geometry} material={materials.follyedge2} />
            <mesh name="polySurface289" geometry={nodes.polySurface289.geometry} material={materials.follyedge2} position={[0, 0, 0.001706]} />
            <mesh name="polySurface290" geometry={nodes.polySurface290.geometry} material={materials.follyedge2} position={[0, 0, 0.001706]} />
            <mesh name="polySurface291" geometry={nodes.polySurface291.geometry} material={materials.follyedge2} position={[0, 0, 0.001706]} />
            <mesh name="polySurface292" geometry={nodes.polySurface292.geometry} material={materials.follyedge2} position={[0, 0, 0.001706]} />
            <mesh name="polySurface293" geometry={nodes.polySurface293.geometry} material={materials.follyedge2} />
          </group>
          <group name="Fern1" position={[-0.458261, -0.105381, -0.41]} rotation={[-Math.PI, 0, 2.983988]} scale={[3.843529, 3.843529, -3.843529]}>
            <group name="transform5" />
            <group name="group1">
              <group name="polySurface51_1">
                <group name="transform43" />
              </group>
              <group name="polySurface52">
                <group name="transform42" />
              </group>
              <group name="polySurface53">
                <group name="transform41" />
              </group>
              <group name="polySurface54">
                <group name="transform40" />
              </group>
              <group name="polySurface55">
                <group name="transform39" />
              </group>
              <group name="polySurface56">
                <group name="transform38" />
              </group>
              <group name="polySurface57">
                <group name="transform37" />
              </group>
              <group name="polySurface58">
                <group name="transform36" />
              </group>
              <group name="polySurface59">
                <group name="transform35" />
              </group>
              <group name="polySurface60">
                <group name="transform34" />
              </group>
              <group name="polySurface61">
                <group name="transform33" />
              </group>
              <group name="polySurface62">
                <group name="transform32" />
              </group>
              <group name="polySurface63">
                <group name="transform31" />
              </group>
              <group name="polySurface64">
                <group name="transform30" />
              </group>
              <group name="polySurface65">
                <group name="transform29" />
              </group>
              <group name="polySurface66">
                <group name="transform28" />
              </group>
              <group name="polySurface67">
                <group name="transform27" />
              </group>
              <group name="polySurface68">
                <group name="transform26" />
              </group>
              <group name="polySurface69">
                <group name="transform25" />
              </group>
              <group name="polySurface70">
                <group name="transform24" />
              </group>
              <group name="polySurface71">
                <group name="transform23" />
              </group>
              <group name="polySurface72">
                <group name="transform22" />
              </group>
              <group name="polySurface73">
                <group name="transform21" />
              </group>
              <group name="polySurface74">
                <group name="transform20" />
              </group>
              <group name="polySurface75">
                <group name="transform19" />
              </group>
              <group name="polySurface76">
                <group name="transform18" />
              </group>
              <group name="polySurface77">
                <group name="transform17" />
              </group>
              <group name="polySurface78">
                <group name="transform16" />
              </group>
              <group name="polySurface79">
                <group name="transform15" />
              </group>
              <group name="polySurface80">
                <group name="transform14" />
              </group>
              <group name="polySurface81">
                <group name="transform13" />
              </group>
              <group name="polySurface82">
                <group name="transform12" />
              </group>
              <group name="polySurface83">
                <group name="transform11" />
              </group>
              <group name="polySurface84">
                <group name="transform10" />
              </group>
              <group name="polySurface85">
                <group name="transform9" />
              </group>
              <group name="polySurface86">
                <group name="transform8" />
              </group>
            </group>
            <group name="group2">
              <group name="polySurface87_1">
                <group name="transform79" />
              </group>
              <group name="polySurface88">
                <group name="transform78" />
              </group>
              <group name="polySurface89">
                <group name="transform77" />
              </group>
              <group name="polySurface90">
                <group name="transform76" />
              </group>
              <group name="polySurface91">
                <group name="transform75" />
              </group>
              <group name="polySurface92">
                <group name="transform74" />
              </group>
              <group name="polySurface93">
                <group name="transform73" />
              </group>
              <group name="polySurface94">
                <group name="transform72" />
              </group>
              <group name="polySurface95">
                <group name="transform71" />
              </group>
              <group name="polySurface96">
                <group name="transform70" />
              </group>
              <group name="polySurface97">
                <group name="transform69" />
              </group>
              <group name="polySurface98">
                <group name="transform68" />
              </group>
              <group name="polySurface99">
                <group name="transform67" />
              </group>
              <group name="polySurface100">
                <group name="transform66" />
              </group>
              <group name="polySurface101">
                <group name="transform65" />
              </group>
              <group name="polySurface102">
                <group name="transform64" />
              </group>
              <group name="polySurface103">
                <group name="transform63" />
              </group>
              <group name="polySurface104">
                <group name="transform62" />
              </group>
              <group name="polySurface105">
                <group name="transform61" />
              </group>
              <group name="polySurface106">
                <group name="transform60" />
              </group>
              <group name="polySurface107">
                <group name="transform59" />
              </group>
              <group name="polySurface108">
                <group name="transform58" />
              </group>
              <group name="polySurface109">
                <group name="transform57" />
              </group>
              <group name="polySurface110">
                <group name="transform56" />
              </group>
              <group name="polySurface111">
                <group name="transform55" />
              </group>
              <group name="polySurface112">
                <group name="transform54" />
              </group>
              <group name="polySurface113">
                <group name="transform53" />
              </group>
              <group name="polySurface114">
                <group name="transform52" />
              </group>
              <group name="polySurface115">
                <group name="transform51" />
              </group>
              <group name="polySurface116">
                <group name="transform50" />
              </group>
              <group name="polySurface117">
                <group name="transform49" />
              </group>
              <group name="polySurface118">
                <group name="transform48" />
              </group>
              <group name="polySurface119">
                <group name="transform47" />
              </group>
              <group name="polySurface120">
                <group name="transform46" />
              </group>
              <group name="polySurface121">
                <group name="transform45" />
              </group>
              <group name="polySurface122">
                <group name="transform44" />
              </group>
            </group>
            <group name="group3">
              <group name="polySurface123">
                <group name="transform115" />
              </group>
              <group name="polySurface124">
                <group name="transform114" />
              </group>
              <group name="polySurface125">
                <group name="transform113" />
              </group>
              <group name="polySurface126">
                <group name="transform112" />
              </group>
              <group name="polySurface127">
                <group name="transform111" />
              </group>
              <group name="polySurface128">
                <group name="transform110" />
              </group>
              <group name="polySurface129">
                <group name="transform109" />
              </group>
              <group name="polySurface130">
                <group name="transform108" />
              </group>
              <group name="polySurface131">
                <group name="transform107" />
              </group>
              <group name="polySurface132">
                <group name="transform106" />
              </group>
              <group name="polySurface133">
                <group name="transform105" />
              </group>
              <group name="polySurface134">
                <group name="transform104" />
              </group>
              <group name="polySurface135">
                <group name="transform103" />
              </group>
              <group name="polySurface136">
                <group name="transform102" />
              </group>
              <group name="polySurface137">
                <group name="transform101" />
              </group>
              <group name="polySurface138">
                <group name="transform100" />
              </group>
              <group name="polySurface139">
                <group name="transform99" />
              </group>
              <group name="polySurface140">
                <group name="transform98" />
              </group>
              <group name="polySurface141">
                <group name="transform97" />
              </group>
              <group name="polySurface142">
                <group name="transform96" />
              </group>
              <group name="polySurface143">
                <group name="transform95" />
              </group>
              <group name="polySurface144">
                <group name="transform94" />
              </group>
              <group name="polySurface145">
                <group name="transform93" />
              </group>
              <group name="polySurface146">
                <group name="transform92" />
              </group>
              <group name="polySurface147">
                <group name="transform91" />
              </group>
              <group name="polySurface148">
                <group name="transform90" />
              </group>
              <group name="polySurface149">
                <group name="transform89" />
              </group>
              <group name="polySurface150">
                <group name="transform88" />
              </group>
              <group name="polySurface151">
                <group name="transform87" />
              </group>
              <group name="polySurface152">
                <group name="transform86" />
              </group>
              <group name="polySurface153">
                <group name="transform85" />
              </group>
              <group name="polySurface154">
                <group name="transform84" />
              </group>
              <group name="polySurface155">
                <group name="transform83" />
              </group>
              <group name="polySurface156">
                <group name="transform82" />
              </group>
              <group name="polySurface157">
                <group name="transform81" />
              </group>
              <group name="polySurface158">
                <group name="transform80" />
              </group>
            </group>
          </group>
          <mesh name="cattail2" geometry={nodes.cattail2.geometry} material={materials.cattails_right} position={[0.02, 0.15, 0]} rotation={[0, 0, -0.08]} />
          <mesh name="cattail1" geometry={nodes.cattail1.geometry} material={materials.cattails_right} position={[0.01, 0.04, 0]} rotation={[0, 0, -0.03]}>
            <group name="DragonFly" position={[1.74, 0.62, 0.43]} rotation={[0.05, -0.69, 2.36]} scale={119.7}>
              <mesh name="wing1" geometry={nodes.wing1.geometry} material={materials.DragonFBXASC032Fly_ncl1_2} rotation={[0, 1.52, 0]} />
              <mesh name="wing2" geometry={nodes.wing2.geometry} material={materials.DragonFBXASC032Fly_ncl1_2} rotation={[0, 1.47, 0]} />
              <mesh name="wing3" geometry={nodes.wing3.geometry} material={materials.DragonFBXASC032Fly_ncl1_2} rotation={[0, -0.74, 0]} />
              <mesh name="wing4" geometry={nodes.wing4.geometry} material={materials.DragonFBXASC032Fly_ncl1_2} rotation={[0, -0.88, 0]} />
              <group name="transform3" />
              <mesh name="body" geometry={nodes.body.geometry} material={materials.dragon} />
            </group>
          </mesh>
          <mesh name="cattail4" geometry={nodes.cattail4.geometry} material={materials.cattailsleft} position={[-0.01, -0.01, 0]} rotation={[0, 0, 0.08]} />
          <mesh name="cattail3" geometry={nodes.cattail3.geometry} material={materials.cattailsleft} position={[-0.02, 0, 0]} rotation={[0, 0, 0.09]} />
          <mesh name="polySurface87_(1)" geometry={nodes['polySurface87_(1)'].geometry} material={materials.follyedge2} position={[-0.03, 0.12, -0.02]} rotation={[-0.17, -0.03, 0.13]} />
          <mesh name="polySurface51_(1)" geometry={nodes['polySurface51_(1)'].geometry} material={materials.follyedge2} rotation={[0, 0, 0.01]} />
          <mesh name="Water_Lily1" geometry={nodes.Water_Lily1.geometry} material={materials.lily} position={[0.04, 0.15, -0.06]} rotation={[-0.08, -0.29, 0.03]} scale={2.54} />
          <mesh name="Water_Lily" geometry={nodes.Water_Lily.geometry} material={materials.lily} position={[1.77, 0.19, 0.15]} rotation={[-0.16, -0.15, 0]} scale={2.54} />
          <mesh name="polySurface123_(1)" geometry={nodes['polySurface123_(1)'].geometry} material={materials.follyedge2} />
        </group>
        <group name="Mesh" position={[-0.33, 0.15, -0.5]} rotation={[0, 0, 0.08]} scale={3.67}>
          <mesh name="polySurface377" geometry={nodes.polySurface377.geometry} material={materials.lambert2} position={[-0.14, 0.03, 0]} rotation={[0.22, 0.34, -0.7]} />
          <mesh name="polySurface378" geometry={nodes.polySurface378.geometry} material={materials.lambert2} position={[0.15, 0.1, 0.07]} rotation={[0.05, -0.43, 0.9]} />
          <mesh name="polySurface374" geometry={nodes.polySurface374.geometry} material={materials.blinn3} />
          <mesh name="polySurface375" geometry={nodes.polySurface375.geometry} material={materials.blinn3} />
          <mesh name="polySurface376" geometry={nodes.polySurface376.geometry} material={materials.blinn3} />
          <mesh name="polySurface379" geometry={nodes.polySurface379.geometry} material={materials.blinn3} />
          <mesh name="polySurface380" geometry={nodes.polySurface380.geometry} material={materials.blinn3} />
          <mesh name="polySurface381" geometry={nodes.polySurface381.geometry} material={materials.blinn3} />
          <mesh name="polySurface382" geometry={nodes.polySurface382.geometry} material={materials.blinn3} />
          <mesh name="polySurface383" geometry={nodes.polySurface383.geometry} material={materials.blinn3} />
          <mesh name="polySurface384" geometry={nodes.polySurface384.geometry} material={materials.blinn3} />
          <mesh name="polySurface385" geometry={nodes.polySurface385.geometry} material={materials.blinn3} />
          <mesh name="polySurface386" geometry={nodes.polySurface386.geometry} material={materials.blinn3} />
          <mesh name="polySurface387" geometry={nodes.polySurface387.geometry} material={materials.blinn3} />
          <mesh name="polySurface388" geometry={nodes.polySurface388.geometry} material={materials.blinn3} />
          <mesh name="polySurface389" geometry={nodes.polySurface389.geometry} material={materials.blinn3} />
          <mesh name="polySurface390" geometry={nodes.polySurface390.geometry} material={materials.blinn3} />
          <mesh name="polySurface391" geometry={nodes.polySurface391.geometry} material={materials.blinn3} />
          <mesh name="polySurface392" geometry={nodes.polySurface392.geometry} material={materials.blinn3} />
          <mesh name="polySurface393" geometry={nodes.polySurface393.geometry} material={materials.blinn3} />
          <mesh name="polySurface394" geometry={nodes.polySurface394.geometry} material={materials.blinn3} />
          <mesh name="polySurface395" geometry={nodes.polySurface395.geometry} material={materials.blinn3} />
          <mesh name="polySurface396" geometry={nodes.polySurface396.geometry} material={materials.blinn3} />
          <mesh name="polySurface397" geometry={nodes.polySurface397.geometry} material={materials.blinn3} />
          <mesh name="polySurface398" geometry={nodes.polySurface398.geometry} material={materials.blinn3} />
          <mesh name="polySurface399" geometry={nodes.polySurface399.geometry} material={materials.blinn3} />
          <mesh name="polySurface400" geometry={nodes.polySurface400.geometry} material={materials.blinn3} />
          <mesh name="polySurface401" geometry={nodes.polySurface401.geometry} material={materials.blinn3} />
          <mesh name="polySurface402" geometry={nodes.polySurface402.geometry} material={materials.blinn3} />
          <mesh name="polySurface403" geometry={nodes.polySurface403.geometry} material={materials.blinn3} />
          <mesh name="polySurface404" geometry={nodes.polySurface404.geometry} material={materials.blinn3} />
          <mesh name="polySurface405" geometry={nodes.polySurface405.geometry} material={materials.blinn3} />
          <mesh name="polySurface406" geometry={nodes.polySurface406.geometry} material={materials.blinn3} />
          <mesh name="polySurface407" geometry={nodes.polySurface407.geometry} material={materials.blinn3} />
          <mesh name="polySurface408" geometry={nodes.polySurface408.geometry} material={materials.blinn3} />
          <mesh name="polySurface409" geometry={nodes.polySurface409.geometry} material={materials.blinn3} />
          <mesh name="polySurface410" geometry={nodes.polySurface410.geometry} material={materials.blinn3} />
          <mesh name="polySurface411" geometry={nodes.polySurface411.geometry} material={materials.blinn3} />
        </group>
        <group name="houseflyMesh" position={[-0.19, 1.04, -0.75]} rotation={[-2.3, -0.68, -3]}>
          <mesh name="polySurface301" geometry={nodes.polySurface301.geometry} material={materials.lambert2} position={[0.06, 0.1, 0.11]} rotation={[-0.25, 0.1, 0.06]} />
          <mesh name="polySurface302" geometry={nodes.polySurface302.geometry} material={materials.lambert2} position={[-0.08, 0.01, -0.04]} rotation={[0.13, 0.02, -0.22]} />
          <mesh name="polySurface299" geometry={nodes.polySurface299.geometry} material={materials.blinn3} />
          <mesh name="polySurface300" geometry={nodes.polySurface300.geometry} material={materials.blinn3} />
          <mesh name="polySurface303" geometry={nodes.polySurface303.geometry} material={materials.blinn3} />
          <mesh name="polySurface304" geometry={nodes.polySurface304.geometry} material={materials.blinn3} />
          <mesh name="polySurface305" geometry={nodes.polySurface305.geometry} material={materials.blinn3} />
          <mesh name="polySurface306" geometry={nodes.polySurface306.geometry} material={materials.blinn3} />
          <mesh name="polySurface307" geometry={nodes.polySurface307.geometry} material={materials.blinn3} />
          <mesh name="polySurface308" geometry={nodes.polySurface308.geometry} material={materials.blinn3} />
          <mesh name="polySurface309" geometry={nodes.polySurface309.geometry} material={materials.blinn3} />
          <mesh name="polySurface310" geometry={nodes.polySurface310.geometry} material={materials.blinn3} />
          <mesh name="polySurface311" geometry={nodes.polySurface311.geometry} material={materials.blinn3} />
          <mesh name="polySurface312" geometry={nodes.polySurface312.geometry} material={materials.blinn3} />
          <mesh name="polySurface314" geometry={nodes.polySurface314.geometry} material={materials.blinn3} />
          <mesh name="polySurface315" geometry={nodes.polySurface315.geometry} material={materials.blinn3} />
          <mesh name="polySurface316" geometry={nodes.polySurface316.geometry} material={materials.blinn3} />
          <mesh name="polySurface317" geometry={nodes.polySurface317.geometry} material={materials.blinn3} />
          <mesh name="polySurface318" geometry={nodes.polySurface318.geometry} material={materials.blinn3} />
          <mesh name="polySurface319" geometry={nodes.polySurface319.geometry} material={materials.blinn3} />
          <mesh name="polySurface320" geometry={nodes.polySurface320.geometry} material={materials.blinn3} />
          <mesh name="polySurface321" geometry={nodes.polySurface321.geometry} material={materials.blinn3} />
          <mesh name="polySurface322" geometry={nodes.polySurface322.geometry} material={materials.blinn3} />
          <mesh name="polySurface323" geometry={nodes.polySurface323.geometry} material={materials.blinn3} />
          <mesh name="polySurface324" geometry={nodes.polySurface324.geometry} material={materials.blinn3} />
          <mesh name="polySurface325" geometry={nodes.polySurface325.geometry} material={materials.blinn3} />
          <mesh name="polySurface327" geometry={nodes.polySurface327.geometry} material={materials.blinn3} />
          <mesh name="polySurface328" geometry={nodes.polySurface328.geometry} material={materials.blinn3} />
          <mesh name="polySurface329" geometry={nodes.polySurface329.geometry} material={materials.blinn3} />
          <mesh name="polySurface330" geometry={nodes.polySurface330.geometry} material={materials.blinn3} />
          <mesh name="polySurface331" geometry={nodes.polySurface331.geometry} material={materials.blinn3} />
          <mesh name="polySurface332" geometry={nodes.polySurface332.geometry} material={materials.blinn3} />
          <mesh name="polySurface333" geometry={nodes.polySurface333.geometry} material={materials.blinn3} />
          <mesh name="polySurface334" geometry={nodes.polySurface334.geometry} material={materials.blinn3} />
          <mesh name="polySurface335" geometry={nodes.polySurface335.geometry} material={materials.blinn3} />
        </group>
        <group name="waves">
          <group name="pasted__group6" position={[0, -0.01, -3.02]} scale={[1, 1, 0.52]}>
            <group name="group5" position={[0, 0, 16.85]} scale={[1, 1, 1.91]}>
              <mesh name="waves1pasted__group4pasted__group6pasted__svgMesh2waves" geometry={nodes.waves1pasted__group4pasted__group6pasted__svgMesh2waves.geometry} material={materials.waves2} />
              <mesh name="waves1pasted__group4pasted__group6pasted__polySurface294pasted__svgMesh1waves" geometry={nodes.waves1pasted__group4pasted__group6pasted__polySurface294pasted__svgMesh1waves.geometry} material={materials.waves2} />
              <mesh name="waves1pasted__group4pasted__group6pasted__polySurface295pasted__svgMesh1waves" geometry={nodes.waves1pasted__group4pasted__group6pasted__polySurface295pasted__svgMesh1waves.geometry} material={materials.waves2} />
              <mesh name="waves1pasted__group4pasted__group6pasted__polySurface296pasted__svgMesh1waves" geometry={nodes.waves1pasted__group4pasted__group6pasted__polySurface296pasted__svgMesh1waves.geometry} material={materials.waves2} />
              <mesh name="waves1pasted__group4pasted__group6pasted__polySurface297pasted__svgMesh1waves" geometry={nodes.waves1pasted__group4pasted__group6pasted__polySurface297pasted__svgMesh1waves.geometry} material={materials.waves2} />
              <mesh name="waves1pasted__group4pasted__group6pasted__polySurface298pasted__svgMesh1waves" geometry={nodes.waves1pasted__group4pasted__group6pasted__polySurface298pasted__svgMesh1waves.geometry} material={materials.waves2} />
              <mesh name="waves1pasted__group4pasted__group6pasted__polySurface299pasted__svgMesh1waves" geometry={nodes.waves1pasted__group4pasted__group6pasted__polySurface299pasted__svgMesh1waves.geometry} material={materials.waves2} />
              <mesh name="waves1pasted__group4pasted__group6pasted__polySurface300pasted__svgMesh1waves" geometry={nodes.waves1pasted__group4pasted__group6pasted__polySurface300pasted__svgMesh1waves.geometry} material={materials.waves2} />
              <mesh name="waves1pasted__group4pasted__group6pasted__polySurface301pasted__svgMesh1waves" geometry={nodes.waves1pasted__group4pasted__group6pasted__polySurface301pasted__svgMesh1waves.geometry} material={materials.waves2} />
              <mesh name="waves1pasted__group4pasted__group6pasted__polySurface302pasted__svgMesh1waves" geometry={nodes.waves1pasted__group4pasted__group6pasted__polySurface302pasted__svgMesh1waves.geometry} material={materials.waves2} />
              <mesh name="waves1pasted__group4pasted__group6pasted__polySurface303pasted__svgMesh1waves" geometry={nodes.waves1pasted__group4pasted__group6pasted__polySurface303pasted__svgMesh1waves.geometry} material={materials.waves2} />
              <mesh name="waves1pasted__group4pasted__group6pasted__polySurface304pasted__svgMesh1waves" geometry={nodes.waves1pasted__group4pasted__group6pasted__polySurface304pasted__svgMesh1waves.geometry} material={materials.waves2} />
              <mesh name="waves1pasted__group4pasted__group6pasted__polySurface305pasted__svgMesh1waves" geometry={nodes.waves1pasted__group4pasted__group6pasted__polySurface305pasted__svgMesh1waves.geometry} material={materials.waves2} />
              <mesh name="waves1pasted__group4pasted__group6pasted__polySurface306pasted__svgMesh1waves" geometry={nodes.waves1pasted__group4pasted__group6pasted__polySurface306pasted__svgMesh1waves.geometry} material={materials.waves2} />
              <mesh name="waves1pasted__group4pasted__group6pasted__polySurface307pasted__svgMesh1waves" geometry={nodes.waves1pasted__group4pasted__group6pasted__polySurface307pasted__svgMesh1waves.geometry} material={materials.waves2} />
              <mesh name="waves1pasted__group4pasted__group6pasted__polySurface308pasted__svgMesh1waves" geometry={nodes.waves1pasted__group4pasted__group6pasted__polySurface308pasted__svgMesh1waves.geometry} material={materials.waves2} />
              <mesh name="waves1pasted__group4pasted__group6pasted__polySurface309pasted__svgMesh1waves" geometry={nodes.waves1pasted__group4pasted__group6pasted__polySurface309pasted__svgMesh1waves.geometry} material={materials.waves2} />
              <mesh name="waves1pasted__group4pasted__group6pasted__polySurface310pasted__svgMesh1waves" geometry={nodes.waves1pasted__group4pasted__group6pasted__polySurface310pasted__svgMesh1waves.geometry} material={materials.waves2} />
              <mesh name="waves1pasted__group4pasted__group6pasted__polySurface311pasted__svgMesh1waves" geometry={nodes.waves1pasted__group4pasted__group6pasted__polySurface311pasted__svgMesh1waves.geometry} material={materials.waves2} />
              <mesh name="waves1pasted__group4pasted__group6pasted__polySurface312pasted__svgMesh1waves" geometry={nodes.waves1pasted__group4pasted__group6pasted__polySurface312pasted__svgMesh1waves.geometry} material={materials.waves2} />
              <mesh name="waves1pasted__group4pasted__group6pasted__polySurface313pasted__svgMesh1waves" geometry={nodes.waves1pasted__group4pasted__group6pasted__polySurface313pasted__svgMesh1waves.geometry} material={materials.waves2} />
              <mesh name="waves1pasted__group4pasted__group6pasted__polySurface314pasted__svgMesh1waves" geometry={nodes.waves1pasted__group4pasted__group6pasted__polySurface314pasted__svgMesh1waves.geometry} material={materials.waves2} />
              <mesh name="waves1pasted__group4pasted__group6pasted__polySurface315pasted__svgMesh1waves" geometry={nodes.waves1pasted__group4pasted__group6pasted__polySurface315pasted__svgMesh1waves.geometry} material={materials.waves2} />
              <mesh name="waves1pasted__group4pasted__group6pasted__polySurface316pasted__svgMesh1waves" geometry={nodes.waves1pasted__group4pasted__group6pasted__polySurface316pasted__svgMesh1waves.geometry} material={materials.waves2} />
              <mesh name="waves1pasted__group4pasted__group6pasted__polySurface317pasted__svgMesh1waves" geometry={nodes.waves1pasted__group4pasted__group6pasted__polySurface317pasted__svgMesh1waves.geometry} material={materials.waves2} />
              <mesh name="waves1pasted__group4pasted__group6pasted__polySurface318pasted__svgMesh1waves" geometry={nodes.waves1pasted__group4pasted__group6pasted__polySurface318pasted__svgMesh1waves.geometry} material={materials.waves2} />
              <mesh name="waves1pasted__group4pasted__group6pasted__polySurface319pasted__svgMesh1waves" geometry={nodes.waves1pasted__group4pasted__group6pasted__polySurface319pasted__svgMesh1waves.geometry} material={materials.waves2} />
              <mesh name="waves1pasted__group4pasted__group6pasted__polySurface320pasted__svgMesh1waves" geometry={nodes.waves1pasted__group4pasted__group6pasted__polySurface320pasted__svgMesh1waves.geometry} material={materials.waves2} />
              <mesh name="waves1pasted__group4pasted__group6pasted__polySurface321pasted__svgMesh1waves" geometry={nodes.waves1pasted__group4pasted__group6pasted__polySurface321pasted__svgMesh1waves.geometry} material={materials.waves2} />
              <mesh name="waves1pasted__group4pasted__group6pasted__polySurface322pasted__svgMesh1waves" geometry={nodes.waves1pasted__group4pasted__group6pasted__polySurface322pasted__svgMesh1waves.geometry} material={materials.waves2} />
              <mesh name="waves1pasted__group4pasted__group6pasted__polySurface323pasted__svgMesh1waves" geometry={nodes.waves1pasted__group4pasted__group6pasted__polySurface323pasted__svgMesh1waves.geometry} material={materials.waves2} />
              <mesh name="waves1pasted__group4pasted__group6pasted__polySurface324pasted__svgMesh1waves" geometry={nodes.waves1pasted__group4pasted__group6pasted__polySurface324pasted__svgMesh1waves.geometry} material={materials.waves2} />
              <mesh name="waves1pasted__group4pasted__group6pasted__polySurface325pasted__svgMesh1waves" geometry={nodes.waves1pasted__group4pasted__group6pasted__polySurface325pasted__svgMesh1waves.geometry} material={materials.waves2} />
              <mesh name="waves1pasted__group4pasted__group6pasted__polySurface326pasted__svgMesh1waves" geometry={nodes.waves1pasted__group4pasted__group6pasted__polySurface326pasted__svgMesh1waves.geometry} material={materials.waves2} />
              <mesh name="waves1pasted__group4pasted__group6pasted__polySurface327pasted__svgMesh1waves" geometry={nodes.waves1pasted__group4pasted__group6pasted__polySurface327pasted__svgMesh1waves.geometry} material={materials.waves2} />
              <mesh name="waves1pasted__group4pasted__group6pasted__polySurface328pasted__svgMesh1waves" geometry={nodes.waves1pasted__group4pasted__group6pasted__polySurface328pasted__svgMesh1waves.geometry} material={materials.waves2} />
              <mesh name="waves1pasted__group4pasted__group6pasted__polySurface329pasted__svgMesh1waves" geometry={nodes.waves1pasted__group4pasted__group6pasted__polySurface329pasted__svgMesh1waves.geometry} material={materials.waves2} />
              <mesh name="waves1pasted__group4pasted__group6pasted__polySurface330pasted__svgMesh1waves" geometry={nodes.waves1pasted__group4pasted__group6pasted__polySurface330pasted__svgMesh1waves.geometry} material={materials.waves2} />
              <mesh name="waves1pasted__group6pasted__group8pasted__svgMesh2waves" geometry={nodes.waves1pasted__group6pasted__group8pasted__svgMesh2waves.geometry} material={materials.waves2} />
              <mesh name="waves1pasted__group6pasted__group8pasted__polySurface294pasted__svgMesh1waves" geometry={nodes.waves1pasted__group6pasted__group8pasted__polySurface294pasted__svgMesh1waves.geometry} material={materials.waves2} />
              <mesh name="waves1pasted__group6pasted__group8pasted__polySurface295pasted__svgMesh1waves" geometry={nodes.waves1pasted__group6pasted__group8pasted__polySurface295pasted__svgMesh1waves.geometry} material={materials.waves2} />
              <mesh name="waves1pasted__group6pasted__group8pasted__polySurface296pasted__svgMesh1waves" geometry={nodes.waves1pasted__group6pasted__group8pasted__polySurface296pasted__svgMesh1waves.geometry} material={materials.waves2} />
              <mesh name="waves1pasted__group6pasted__group8pasted__polySurface297pasted__svgMesh1waves" geometry={nodes.waves1pasted__group6pasted__group8pasted__polySurface297pasted__svgMesh1waves.geometry} material={materials.waves2} />
              <mesh name="waves1pasted__group6pasted__group8pasted__polySurface298pasted__svgMesh1waves" geometry={nodes.waves1pasted__group6pasted__group8pasted__polySurface298pasted__svgMesh1waves.geometry} material={materials.waves2} />
              <mesh name="waves1pasted__group6pasted__group8pasted__polySurface299pasted__svgMesh1waves" geometry={nodes.waves1pasted__group6pasted__group8pasted__polySurface299pasted__svgMesh1waves.geometry} material={materials.waves2} />
              <mesh name="waves1pasted__group6pasted__group8pasted__polySurface300pasted__svgMesh1waves" geometry={nodes.waves1pasted__group6pasted__group8pasted__polySurface300pasted__svgMesh1waves.geometry} material={materials.waves2} />
              <mesh name="waves1pasted__group6pasted__group8pasted__polySurface301pasted__svgMesh1waves" geometry={nodes.waves1pasted__group6pasted__group8pasted__polySurface301pasted__svgMesh1waves.geometry} material={materials.waves2} />
              <mesh name="waves1pasted__group6pasted__group8pasted__polySurface302pasted__svgMesh1waves" geometry={nodes.waves1pasted__group6pasted__group8pasted__polySurface302pasted__svgMesh1waves.geometry} material={materials.waves2} />
              <mesh name="waves1pasted__group6pasted__group8pasted__polySurface303pasted__svgMesh1waves" geometry={nodes.waves1pasted__group6pasted__group8pasted__polySurface303pasted__svgMesh1waves.geometry} material={materials.waves2} />
              <mesh name="waves1pasted__group6pasted__group8pasted__polySurface304pasted__svgMesh1waves" geometry={nodes.waves1pasted__group6pasted__group8pasted__polySurface304pasted__svgMesh1waves.geometry} material={materials.waves2} />
              <mesh name="waves1pasted__group6pasted__group8pasted__polySurface305pasted__svgMesh1waves" geometry={nodes.waves1pasted__group6pasted__group8pasted__polySurface305pasted__svgMesh1waves.geometry} material={materials.waves2} />
              <mesh name="waves1pasted__group6pasted__group8pasted__polySurface306pasted__svgMesh1waves" geometry={nodes.waves1pasted__group6pasted__group8pasted__polySurface306pasted__svgMesh1waves.geometry} material={materials.waves2} />
              <mesh name="waves1pasted__group6pasted__group8pasted__polySurface307pasted__svgMesh1waves" geometry={nodes.waves1pasted__group6pasted__group8pasted__polySurface307pasted__svgMesh1waves.geometry} material={materials.waves2} />
              <mesh name="waves1pasted__group6pasted__group8pasted__polySurface308pasted__svgMesh1waves" geometry={nodes.waves1pasted__group6pasted__group8pasted__polySurface308pasted__svgMesh1waves.geometry} material={materials.waves2} />
              <mesh name="waves1pasted__group6pasted__group8pasted__polySurface309pasted__svgMesh1waves" geometry={nodes.waves1pasted__group6pasted__group8pasted__polySurface309pasted__svgMesh1waves.geometry} material={materials.waves2} />
              <mesh name="waves1pasted__group6pasted__group8pasted__polySurface310pasted__svgMesh1waves" geometry={nodes.waves1pasted__group6pasted__group8pasted__polySurface310pasted__svgMesh1waves.geometry} material={materials.waves2} />
              <mesh name="waves1pasted__group6pasted__group8pasted__polySurface311pasted__svgMesh1waves" geometry={nodes.waves1pasted__group6pasted__group8pasted__polySurface311pasted__svgMesh1waves.geometry} material={materials.waves2} />
              <mesh name="waves1pasted__group6pasted__group8pasted__polySurface312pasted__svgMesh1waves" geometry={nodes.waves1pasted__group6pasted__group8pasted__polySurface312pasted__svgMesh1waves.geometry} material={materials.waves2} />
              <mesh name="waves1pasted__group6pasted__group8pasted__polySurface313pasted__svgMesh1waves" geometry={nodes.waves1pasted__group6pasted__group8pasted__polySurface313pasted__svgMesh1waves.geometry} material={materials.waves2} />
              <mesh name="waves1pasted__group6pasted__group8pasted__polySurface314pasted__svgMesh1waves" geometry={nodes.waves1pasted__group6pasted__group8pasted__polySurface314pasted__svgMesh1waves.geometry} material={materials.waves2} />
              <mesh name="waves1pasted__group6pasted__group8pasted__polySurface315pasted__svgMesh1waves" geometry={nodes.waves1pasted__group6pasted__group8pasted__polySurface315pasted__svgMesh1waves.geometry} material={materials.waves2} />
              <mesh name="waves1pasted__group6pasted__group8pasted__polySurface316pasted__svgMesh1waves" geometry={nodes.waves1pasted__group6pasted__group8pasted__polySurface316pasted__svgMesh1waves.geometry} material={materials.waves2} />
              <mesh name="waves1pasted__group6pasted__group8pasted__polySurface317pasted__svgMesh1waves" geometry={nodes.waves1pasted__group6pasted__group8pasted__polySurface317pasted__svgMesh1waves.geometry} material={materials.waves2} />
              <mesh name="waves1pasted__group6pasted__group8pasted__polySurface318pasted__svgMesh1waves" geometry={nodes.waves1pasted__group6pasted__group8pasted__polySurface318pasted__svgMesh1waves.geometry} material={materials.waves2} />
              <mesh name="waves1pasted__group6pasted__group8pasted__polySurface319pasted__svgMesh1waves" geometry={nodes.waves1pasted__group6pasted__group8pasted__polySurface319pasted__svgMesh1waves.geometry} material={materials.waves2} />
              <mesh name="waves1pasted__group6pasted__group8pasted__polySurface320pasted__svgMesh1waves" geometry={nodes.waves1pasted__group6pasted__group8pasted__polySurface320pasted__svgMesh1waves.geometry} material={materials.waves2} />
              <mesh name="waves1pasted__group6pasted__group8pasted__polySurface321pasted__svgMesh1waves" geometry={nodes.waves1pasted__group6pasted__group8pasted__polySurface321pasted__svgMesh1waves.geometry} material={materials.waves2} />
              <mesh name="waves1pasted__group6pasted__group8pasted__polySurface322pasted__svgMesh1waves" geometry={nodes.waves1pasted__group6pasted__group8pasted__polySurface322pasted__svgMesh1waves.geometry} material={materials.waves2} />
              <mesh name="waves1pasted__group6pasted__group8pasted__polySurface323pasted__svgMesh1waves" geometry={nodes.waves1pasted__group6pasted__group8pasted__polySurface323pasted__svgMesh1waves.geometry} material={materials.waves2} />
              <mesh name="waves1pasted__group6pasted__group8pasted__polySurface324pasted__svgMesh1waves" geometry={nodes.waves1pasted__group6pasted__group8pasted__polySurface324pasted__svgMesh1waves.geometry} material={materials.waves2} />
              <mesh name="waves1pasted__group6pasted__group8pasted__polySurface325pasted__svgMesh1waves" geometry={nodes.waves1pasted__group6pasted__group8pasted__polySurface325pasted__svgMesh1waves.geometry} material={materials.waves2} />
              <mesh name="waves1pasted__group6pasted__group8pasted__polySurface326pasted__svgMesh1waves" geometry={nodes.waves1pasted__group6pasted__group8pasted__polySurface326pasted__svgMesh1waves.geometry} material={materials.waves2} />
              <mesh name="waves1pasted__group6pasted__group8pasted__polySurface327pasted__svgMesh1waves" geometry={nodes.waves1pasted__group6pasted__group8pasted__polySurface327pasted__svgMesh1waves.geometry} material={materials.waves2} />
              <mesh name="waves1pasted__group6pasted__group8pasted__polySurface328pasted__svgMesh1waves" geometry={nodes.waves1pasted__group6pasted__group8pasted__polySurface328pasted__svgMesh1waves.geometry} material={materials.waves2} />
              <mesh name="waves1pasted__group6pasted__group8pasted__polySurface329pasted__svgMesh1waves" geometry={nodes.waves1pasted__group6pasted__group8pasted__polySurface329pasted__svgMesh1waves.geometry} material={materials.waves2} />
              <mesh name="waves1pasted__group6pasted__group8pasted__polySurface330pasted__svgMesh1waves" geometry={nodes.waves1pasted__group6pasted__group8pasted__polySurface330pasted__svgMesh1waves.geometry} material={materials.waves2} />
              <mesh name="waves1pasted__group4pasted__group6pasted__group7pasted__svgMesh2waves" geometry={nodes.waves1pasted__group4pasted__group6pasted__group7pasted__svgMesh2waves.geometry} material={materials.waves2} />
              <mesh name="waves1pasted__group4pasted__group6pasted__group7pasted__polySurface294pasted__svgMesh1waves" geometry={nodes.waves1pasted__group4pasted__group6pasted__group7pasted__polySurface294pasted__svgMesh1waves.geometry} material={materials.waves2} />
              <mesh name="waves1pasted__group4pasted__group6pasted__group7pasted__polySurface295pasted__svgMesh1waves" geometry={nodes.waves1pasted__group4pasted__group6pasted__group7pasted__polySurface295pasted__svgMesh1waves.geometry} material={materials.waves2} />
              <mesh name="waves1pasted__group4pasted__group6pasted__group7pasted__polySurface296pasted__svgMesh1waves" geometry={nodes.waves1pasted__group4pasted__group6pasted__group7pasted__polySurface296pasted__svgMesh1waves.geometry} material={materials.waves2} />
              <mesh name="waves1pasted__group4pasted__group6pasted__group7pasted__polySurface297pasted__svgMesh1waves" geometry={nodes.waves1pasted__group4pasted__group6pasted__group7pasted__polySurface297pasted__svgMesh1waves.geometry} material={materials.waves2} />
              <mesh name="waves1pasted__group4pasted__group6pasted__group7pasted__polySurface298pasted__svgMesh1waves" geometry={nodes.waves1pasted__group4pasted__group6pasted__group7pasted__polySurface298pasted__svgMesh1waves.geometry} material={materials.waves2} />
              <mesh name="waves1pasted__group4pasted__group6pasted__group7pasted__polySurface299pasted__svgMesh1waves" geometry={nodes.waves1pasted__group4pasted__group6pasted__group7pasted__polySurface299pasted__svgMesh1waves.geometry} material={materials.waves2} />
              <mesh name="waves1pasted__group4pasted__group6pasted__group7pasted__polySurface300pasted__svgMesh1waves" geometry={nodes.waves1pasted__group4pasted__group6pasted__group7pasted__polySurface300pasted__svgMesh1waves.geometry} material={materials.waves2} />
              <mesh name="waves1pasted__group4pasted__group6pasted__group7pasted__polySurface301pasted__svgMesh1waves" geometry={nodes.waves1pasted__group4pasted__group6pasted__group7pasted__polySurface301pasted__svgMesh1waves.geometry} material={materials.waves2} />
              <mesh name="waves1pasted__group4pasted__group6pasted__group7pasted__polySurface302pasted__svgMesh1waves" geometry={nodes.waves1pasted__group4pasted__group6pasted__group7pasted__polySurface302pasted__svgMesh1waves.geometry} material={materials.waves2} />
              <mesh name="waves1pasted__group4pasted__group6pasted__group7pasted__polySurface303pasted__svgMesh1waves" geometry={nodes.waves1pasted__group4pasted__group6pasted__group7pasted__polySurface303pasted__svgMesh1waves.geometry} material={materials.waves2} />
              <mesh name="waves1pasted__group4pasted__group6pasted__group7pasted__polySurface304pasted__svgMesh1waves" geometry={nodes.waves1pasted__group4pasted__group6pasted__group7pasted__polySurface304pasted__svgMesh1waves.geometry} material={materials.waves2} />
              <mesh name="waves1pasted__group4pasted__group6pasted__group7pasted__polySurface305pasted__svgMesh1waves" geometry={nodes.waves1pasted__group4pasted__group6pasted__group7pasted__polySurface305pasted__svgMesh1waves.geometry} material={materials.waves2} />
              <mesh name="waves1pasted__group4pasted__group6pasted__group7pasted__polySurface306pasted__svgMesh1waves" geometry={nodes.waves1pasted__group4pasted__group6pasted__group7pasted__polySurface306pasted__svgMesh1waves.geometry} material={materials.waves2} />
              <mesh name="waves1pasted__group4pasted__group6pasted__group7pasted__polySurface307pasted__svgMesh1waves" geometry={nodes.waves1pasted__group4pasted__group6pasted__group7pasted__polySurface307pasted__svgMesh1waves.geometry} material={materials.waves2} />
              <mesh name="waves1pasted__group4pasted__group6pasted__group7pasted__polySurface308pasted__svgMesh1waves" geometry={nodes.waves1pasted__group4pasted__group6pasted__group7pasted__polySurface308pasted__svgMesh1waves.geometry} material={materials.waves2} />
              <mesh name="waves1pasted__group4pasted__group6pasted__group7pasted__polySurface309pasted__svgMesh1waves" geometry={nodes.waves1pasted__group4pasted__group6pasted__group7pasted__polySurface309pasted__svgMesh1waves.geometry} material={materials.waves2} />
              <mesh name="waves1pasted__group4pasted__group6pasted__group7pasted__polySurface310pasted__svgMesh1waves" geometry={nodes.waves1pasted__group4pasted__group6pasted__group7pasted__polySurface310pasted__svgMesh1waves.geometry} material={materials.waves2} />
              <mesh name="waves1pasted__group4pasted__group6pasted__group7pasted__polySurface311pasted__svgMesh1waves" geometry={nodes.waves1pasted__group4pasted__group6pasted__group7pasted__polySurface311pasted__svgMesh1waves.geometry} material={materials.waves2} />
              <mesh name="waves1pasted__group4pasted__group6pasted__group7pasted__polySurface312pasted__svgMesh1waves" geometry={nodes.waves1pasted__group4pasted__group6pasted__group7pasted__polySurface312pasted__svgMesh1waves.geometry} material={materials.waves2} />
              <mesh name="waves1pasted__group4pasted__group6pasted__group7pasted__polySurface313pasted__svgMesh1waves" geometry={nodes.waves1pasted__group4pasted__group6pasted__group7pasted__polySurface313pasted__svgMesh1waves.geometry} material={materials.waves2} />
              <mesh name="waves1pasted__group4pasted__group6pasted__group7pasted__polySurface314pasted__svgMesh1waves" geometry={nodes.waves1pasted__group4pasted__group6pasted__group7pasted__polySurface314pasted__svgMesh1waves.geometry} material={materials.waves2} />
              <mesh name="waves1pasted__group4pasted__group6pasted__group7pasted__polySurface315pasted__svgMesh1waves" geometry={nodes.waves1pasted__group4pasted__group6pasted__group7pasted__polySurface315pasted__svgMesh1waves.geometry} material={materials.waves2} />
              <mesh name="waves1pasted__group4pasted__group6pasted__group7pasted__polySurface316pasted__svgMesh1waves" geometry={nodes.waves1pasted__group4pasted__group6pasted__group7pasted__polySurface316pasted__svgMesh1waves.geometry} material={materials.waves2} />
              <mesh name="waves1pasted__group4pasted__group6pasted__group7pasted__polySurface317pasted__svgMesh1waves" geometry={nodes.waves1pasted__group4pasted__group6pasted__group7pasted__polySurface317pasted__svgMesh1waves.geometry} material={materials.waves2} />
              <mesh name="waves1pasted__group4pasted__group6pasted__group7pasted__polySurface318pasted__svgMesh1waves" geometry={nodes.waves1pasted__group4pasted__group6pasted__group7pasted__polySurface318pasted__svgMesh1waves.geometry} material={materials.waves2} />
              <mesh name="waves1pasted__group4pasted__group6pasted__group7pasted__polySurface319pasted__svgMesh1waves" geometry={nodes.waves1pasted__group4pasted__group6pasted__group7pasted__polySurface319pasted__svgMesh1waves.geometry} material={materials.waves2} />
              <mesh name="waves1pasted__group4pasted__group6pasted__group7pasted__polySurface320pasted__svgMesh1waves" geometry={nodes.waves1pasted__group4pasted__group6pasted__group7pasted__polySurface320pasted__svgMesh1waves.geometry} material={materials.waves2} />
              <mesh name="waves1pasted__group4pasted__group6pasted__group7pasted__polySurface321pasted__svgMesh1waves" geometry={nodes.waves1pasted__group4pasted__group6pasted__group7pasted__polySurface321pasted__svgMesh1waves.geometry} material={materials.waves2} />
              <mesh name="waves1pasted__group4pasted__group6pasted__group7pasted__polySurface322pasted__svgMesh1waves" geometry={nodes.waves1pasted__group4pasted__group6pasted__group7pasted__polySurface322pasted__svgMesh1waves.geometry} material={materials.waves2} />
              <mesh name="waves1pasted__group4pasted__group6pasted__group7pasted__polySurface323pasted__svgMesh1waves" geometry={nodes.waves1pasted__group4pasted__group6pasted__group7pasted__polySurface323pasted__svgMesh1waves.geometry} material={materials.waves2} />
              <mesh name="waves1pasted__group4pasted__group6pasted__group7pasted__polySurface324pasted__svgMesh1waves" geometry={nodes.waves1pasted__group4pasted__group6pasted__group7pasted__polySurface324pasted__svgMesh1waves.geometry} material={materials.waves2} />
              <mesh name="waves1pasted__group4pasted__group6pasted__group7pasted__polySurface325pasted__svgMesh1waves" geometry={nodes.waves1pasted__group4pasted__group6pasted__group7pasted__polySurface325pasted__svgMesh1waves.geometry} material={materials.waves2} />
              <mesh name="waves1pasted__group4pasted__group6pasted__group7pasted__polySurface326pasted__svgMesh1waves" geometry={nodes.waves1pasted__group4pasted__group6pasted__group7pasted__polySurface326pasted__svgMesh1waves.geometry} material={materials.waves2} />
              <mesh name="waves1pasted__group4pasted__group6pasted__group7pasted__polySurface327pasted__svgMesh1waves" geometry={nodes.waves1pasted__group4pasted__group6pasted__group7pasted__polySurface327pasted__svgMesh1waves.geometry} material={materials.waves2} />
              <mesh name="waves1pasted__group4pasted__group6pasted__group7pasted__polySurface328pasted__svgMesh1waves" geometry={nodes.waves1pasted__group4pasted__group6pasted__group7pasted__polySurface328pasted__svgMesh1waves.geometry} material={materials.waves2} />
              <mesh name="waves1pasted__group4pasted__group6pasted__group7pasted__polySurface329pasted__svgMesh1waves" geometry={nodes.waves1pasted__group4pasted__group6pasted__group7pasted__polySurface329pasted__svgMesh1waves.geometry} material={materials.waves2} />
              <mesh name="waves1pasted__group4pasted__group6pasted__group7pasted__polySurface330pasted__svgMesh1waves" geometry={nodes.waves1pasted__group4pasted__group6pasted__group7pasted__polySurface330pasted__svgMesh1waves.geometry} material={materials.waves2} />
            </group>
          </group>
        </group>
        <PerspectiveCamera name="Main2" makeDefault={false} far={1000000000} near={1} fov={26.99} position={[0.9, 0.91, 4.16]} />
        <mesh name="Sky" geometry={nodes.Sky.geometry} material={materials.sky} position={[1.27, -5.56, -18.9]} scale={5.19} />
        <mesh name="pPlane2" geometry={nodes.pPlane2.geometry} material={materials.water} position={[0.9, 0, -7.99]} scale={2146.48} />
        <mesh name="Leaf1" geometry={nodes.Leaf1.geometry} material={materials.leaf1} position={[0.1, -0.16, 1.06]} rotation={[-1.12, 0.32, 0.06]} scale={0.54} />
        <mesh name="Leaf2" geometry={nodes.Leaf2.geometry} material={materials.laef1} position={[1.66, 1, 0.32]} rotation={[-1.77, -0.34, -0.25]} scale={94.68} />
        <mesh name="svgMesh1" geometry={nodes.svgMesh1.geometry} material={materials.leaf4} position={[1.13, 0.28, -0.02]} rotation={[-2.56, -0.25, 2.92]} scale={[1, 1, 2.93]}>
          <mesh name="leaf3svgMesh1" geometry={nodes.leaf3svgMesh1.geometry} material={materials.leaf4} position={[-1.13, -1.9, 0.01]} scale={[1, 1, 0.34]} />
        </mesh>
      </group>
    </group>
  )
}

useGLTF.preload('/models/all_002.glb')

/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.3 boxes.glb
*/

import React, { useEffect, useRef, useState } from 'react'
import { useGLTF, useAnimations, useVideoTexture, Float } from '@react-three/drei'
import * as THREE from 'three'
import { useStore } from '../../../store'
import { useFrame } from '@react-three/fiber'
import { isMobile } from 'react-device-detect'

export function Model(props) {
  const group = useRef()
  const box = useRef()
  const box2 = useRef()
  const { nodes, materials, animations } = useGLTF('/models/boxes.glb')
  const { actions } = useAnimations(animations, group)
  const amimation3dFinished = useStore(state => state.amimation3dFinished)

  const [swapBoxesVisibility, setSwapBoxesVisibility] = useState(false)

  const mouseX = React.useRef(0)
  const mouseY = React.useRef(0)
  const targetRef = React.useRef(new THREE.Vector3())

  const startFlareAnimaition = useStore(state => state.startFlareAnimaition)

  // const [{ rotationX, rotationY }, setSpring] = useSpring(() => ({
  //   rotationX: 0,
  //   rotationY: 0,
  // }))

  const onMouseMove = (event) => {
    mouseX.current = ( event.clientX / window.innerWidth ) * 2 - 1;
    mouseY.current = - ( event.clientY / window.innerHeight ) * 2 + 1;
  }

  const simulationGyroscopeOrientation = (e) => {
    const { alpha, beta, gamma } = e
    mouseY.current = -(beta - 90) * 0.02;
    mouseX.current = -(gamma) * 0.02;
  }

  useEffect(() => {
    !isMobile && document.addEventListener('mousemove', (onMouseMove));
    window.addEventListener(
      'deviceorientation',
      simulationGyroscopeOrientation,
    )
  }, [])

  useEffect(() => {
    actions['All Animations'].setLoop(THREE.LoopOnce).clampWhenFinished = true
    props.playAnimationFlag && actions['All Animations'].play()
    
  }, [props.playAnimationFlag])

  useEffect(() => {
    if (startFlareAnimaition) {
      setTimeout(() => {
        
        setSwapBoxesVisibility(true)
      }, 800);
    }
  }, [startFlareAnimaition])

  useFrame(() => {
    if (box2.current && box.current) {
      box.current.rotation.y = mouseX.current * 0.5
      box.current.rotation.x = - mouseY.current * 0.5

      box2.current.rotation.y = mouseX.current * 0.5
      box2.current.rotation.x = - mouseY.current * 0.5
    }
  })
  // const texture = useVideoTexture('/videos/flare_with_alpha.mp4')

  useEffect(() => {
    // console.log("A: texture123", texture);
    // texture.format = THREE.RGBAFormat
    // materials.pasted__pasted__blinn2.depthWrite = true
    // materials.pasted__pasted__blinn4.depthWrite = true
  }, [])

  return (
    <group ref={group} {...props} dispose={null}>
      {/* <mesh position={[0.91, 0.77, 1.34]}>
        <planeBufferGeometry args={[1,1,1]}/>
        <meshBasicMaterial map={texture} transparent={true}/>
      </mesh> */}
      <group>
        <group name="Lily_Pad" position={[2.09, 0.01, -0.66]} rotation={[-2.97, 1.46, 3]} scale={0.88}>
          <mesh receiveShadow name="pasted__pasted__polySurface294" geometry={nodes.pasted__pasted__polySurface294.geometry} material={materials.pasted__pasted__lambert21} />
        </group>
        <group name="ripples">
          <mesh name="pasted__pasted__polySurface295" geometry={nodes.pasted__pasted__polySurface295.geometry} material={materials.pasted__pasted__lambert14} position={[0.76, -0.12, 0.46]} rotation={[0.02, 0.49, 0.02]} scale={1.07} />
          <mesh name="pasted__pasted__polySurface297" geometry={nodes.pasted__pasted__polySurface297.geometry} material={materials.pasted__pasted__lambert14} position={[0.76, -0.1, 0.46]} rotation={[0.02, 0.49, 0.02]} scale={1.07} />
          <mesh name="pasted__pasted__polySurface296" geometry={nodes.pasted__pasted__polySurface296.geometry} material={materials.pasted__pasted__lambert14} position={[0.76, -0.09, 0.46]} rotation={[0.02, 0.49, 0.02]} scale={1.07} />
        </group>
        <group name="group">
          <group name="pasted__group" position={[0.91, 0.77, 0.34]}>
            <group name="pasted__pasted__box">
            {/* <Html
              >
                {mouseX.current}
              </Html> */}
              <mesh visible={!swapBoxesVisibility} ref={box2} name="pasted__pasted__Object001" geometry={nodes.pasted__pasted__Object001.geometry} material={materials.pasted__pasted__blinn2} />
              {/* <Float
  speed={1} // Animation speed, defaults to 1
  rotationIntensity={0.5} // XYZ rotation intensity, defaults to 1
  floatIntensity={0.5} // Up/down float intensity, works like a multiplier with floatingRange,defaults to 1
  // floatingRange={[1, 10]} // Range of y-axis values the object will float within, defaults to [-0.1,0.1]
> */}
              <mesh visible={swapBoxesVisibility} ref={box} name="pasted__pasted__Object002" geometry={nodes.pasted__pasted__Object002.geometry} material={materials.pasted__pasted__blinn4} />
  {/* <mesh /> */}
{/* </Float> */}
            </group>
          </group>
        </group>
      </group>
    </group>
  )
}

useGLTF.preload('/models/boxes.glb')

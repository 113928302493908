/* eslint-disable react/display-name */

import React, {
forwardRef,
useEffect,
useImperativeHandle,
useRef,
useState,
} from 'react'
// import useStore from '@/helpers/store'
import { OrbitControls } from 'three-stdlib'
import { useFrame } from '@react-three/fiber'
import * as THREE from 'three'
// import SmoothZoom from '@/helpers/smoothZoom'
import { isMobile } from 'react-device-detect'
import lerp from 'lerp'

const DEFAULT_TARGET = new THREE.Vector3()

const CameraEffects = forwardRef((props, ref) => {
  const { controls, innerCamera, menuIsOpen } = props

  // const dom = useStore((state) => state.dom)
  const rotationTarget = useRef(null)
  // const controlsSmooth = useRef(null)
  // const zoomEnabledRef = useRef(props.zoomEnabled)
  // const [zoomIsActive, setZoomIsActive] = useState(false)
  const enableRef = useRef(false)
  const { camera } = props
  const [nextRotationTarget] = useState(() => new THREE.Euler())
  const [deviceOrientation, setDeviceOrientation] = useState({
      alpha: 0,
      beta: 0,
      gamma: 0,
  })
  const deviceOrientationRef = useRef(deviceOrientation)
  const [screenRotation, setScreenRotation] = useState({ mx: 0, my: 0 })
  const screenRotationRef = useRef(screenRotation)
  const [gyroscopeEnabled, setGyroscopeEnabled] = useState(true)
  const gyroscopeEnabledRef = useRef(gyroscopeEnabled)
  const _innerCameraPosition = React.useRef(new THREE.Vector3())
  const newPos = React.useRef(0)

  useEffect(() => {
      deviceOrientationRef.current = deviceOrientation
  }, [deviceOrientation])
  useEffect(() => {
      screenRotationRef.current = screenRotation
  }, [screenRotation])
  useEffect(() => {
      gyroscopeEnabledRef.current = gyroscopeEnabled
  }, [gyroscopeEnabled])

  const simulationMouseOrientation = (e) => {
      if (!isMobile) {
      if (controls && controls.current) controls.current.enableDamping = false
      const { x, y } = { x: e.clientX, y: e.clientY }
      const mx = (x / window.innerWidth) * 2 - 1
      const my = -(y / window.innerHeight) * 2 + 1

      const rotationMultiplyX = props.rotationMultiplyX
          ? props.rotationMultiplyX
          : 0.5
      const rotationMultiplyY = props.rotationMultiplyY
          ? props.rotationMultiplyY
          : 0.5
      if (props.inverse)
          _innerCameraPosition.current.set(
          -mx * rotationMultiplyX,
          -my * rotationMultiplyY,
          0
          )
      if (!props.inverse)
          _innerCameraPosition.current.set(
          mx * rotationMultiplyX,
          my * rotationMultiplyY,
          0
          )
      }
  }

  const simulationGyroscopeOrientation = (e) => {
      if (enableRef.current && gyroscopeEnabledRef.current) {
      const { alpha, beta, gamma } = e
      const prevOrientation = deviceOrientationRef.current

      setDeviceOrientation({ alpha, beta, gamma })
      const deltaOrienation = {
          alpha: alpha - prevOrientation.alpha,
          beta: beta - prevOrientation.beta,
          gamma: gamma - prevOrientation.gamma,
      }

      const rotationMultiplyZ = props.rotationMultiplyX
          ? props.rotationMultiplyX
          : 0.01
      const rotationMultiplyY = props.rotationMultiplyY
          ? props.rotationMultiplyY
          : 0.01

      if (controls && controls.current) controls.current.enableDamping = false

      let mx = screenRotationRef.current.mx
      let my = screenRotationRef.current.my + deltaOrienation.beta / 90

      if (deltaOrienation.gamma / 90 >= -1 && deltaOrienation.gamma / 90 <= 1)
          mx = mx + deltaOrienation.gamma / 90
      if (my >= 1) my = 1
      if (my <= -1) my = -1
      nextRotationTarget.set(
          0,
          +(mx * rotationMultiplyY),
          -(my * rotationMultiplyZ)
      )

      setScreenRotation({ mx: mx, my: my })

      if (nextRotationTarget && rotationTarget.current) {
          rotationTarget.current.rotation.copy(nextRotationTarget)
          rotationTarget.current.updateMatrixWorld()
      }
      _innerCameraPosition.current.set(mx * 0.5, my * 0.5, 0)
      }
  }

  // useEffect(
  //     () => {
  //     //@ts-ignore
  //     zoomEnabledRef.current = props.zoomEnabled
  //     },
  //     //@ts-ignore
  //     [props.zoomEnabled]
  // )

  // const handleStart = () => {
  //     setZoomIsActive(true)
  //     return zoomEnabledRef
  // }

  // const handleFinish = () => {
  //     setZoomIsActive(false)
  // }

  useImperativeHandle(ref, () => ({}), [])

  const disableGyroscope = () => {
      setGyroscopeEnabled(false)
      setScreenRotation({ mx: 0, my: 0 })
  }

  const enableGyroscope = () => {
      setGyroscopeEnabled(true)
  }

  useEffect(() => {
      enableRef.current = props.enable
      //  && !zoomIsActive
  }, [props.enable])

  useEffect(() => {
      setTimeout(() => {
        if (!isMobile)
            window.addEventListener('mousemove', simulationMouseOrientation)
      
        // window.addEventListener(
        //     'deviceorientation',
        //     simulationGyroscopeOrientation,
        //     true
        // )
      //   window.addEventListener('touchstart', disableGyroscope)
      //   window.addEventListener('touchend', enableGyroscope)
      })
      return () => {
      if (!isMobile)
          window.addEventListener('mousemove', simulationMouseOrientation)
      // window.removeEventListener(
      //     'deviceorientation',
      //     simulationGyroscopeOrientation,
      //     true
      // )
    //   window.removeEventListener('touchstart', disableGyroscope)
    //   window.removeEventListener('touchend', enableGyroscope)
      }
  }, [])

  // useEffect(() => {
  //     if (controls.current && !controlsSmooth.current && camera) {
  //       // const container = document.getElementById('container3d')
  //       // controlsSmooth.current = new SmoothZoom(
  //       //     controls.current,
  //       //     camera,
  //       //     container,
  //       //     [handleStart],
  //       //     [handleFinish],
  //       //     props.simplified
  //       // )
  //     }
  // }, [controls.current, camera])

  useEffect(() => {
      if (menuIsOpen) {
      innerCamera.position.set(
          _innerCameraPosition.current.x,
          _innerCameraPosition.current.y,
          _innerCameraPosition.current.z
      )
      }
  }, [menuIsOpen])

  //Limit camera x/y offset
  useFrame(() => {
      if (innerCamera) {
      if (Math.abs(innerCamera.position.x) >= 0.6)
          innerCamera.position.x = Math.sign(innerCamera.position.x) * 0.6
      if (Math.abs(innerCamera.position.y) >= 0.6)
          innerCamera.position.y = Math.sign(innerCamera.position.y) * 0.6
      }
  })

  useFrame((state, delta) => {
      // controlsSmooth.current?.update()

      if (innerCamera) {
      if (enableRef.current) {
          innerCamera.position.lerp(_innerCameraPosition.current, 10 * delta)
      }

      const newDelta = (_innerCameraPosition.current.x - newPos.current) * 2
      newPos.current = _innerCameraPosition.current.x

      if (enableRef.current) {
          if (props.inverse)
          innerCamera.rotation.set(
              0,
              0,
              lerp(innerCamera.rotation.z, newDelta, 0.05) + Math.PI / 4
          )
          if (!props.inverse)
          innerCamera.rotation.set(
              0,
              0,
              lerp(innerCamera.rotation.z, newDelta, 0.05)
          )
      }

      // innerCamera.position.updateProjectionMatrix()
      }
  })

  return (
      <>
      {/* <mesh ref={rotationTarget} visible={true}>
          <sphereGeometry args={[0.1]} />
          <meshStandardMaterial color={'red'} />
          <mesh ref={rotationCamera}>
          <sphereGeometry args={[0.1]} />
          <meshStandardMaterial color={'yellow'} />
          </mesh>
      </mesh> */}
      </>
  )
})

export default CameraEffects
  
import { useLoaderStore, useStore } from "../../store"
import { Howl, Howler } from 'howler'


export default class AudioManager {
  soundsEnabled = true
  readyToPlaySounds = false
  sounds = {
    "Enviroment": {
      url: '/sounds/02O - FoxFarm LOOP.mp3',
      loaded: false,
      sound: null,
      loop: true
    },
    "Jump": {
      url: '/sounds/02P - Frog Croak with Water Movement.mp3',
      loaded: false,
      sound: null,
      loop: false
    },
  }
  loadSounds = () => {
    for (const [key, value] of Object.entries(this.sounds)) {
      const sound = new Howl({
        src: value.url,
        loop: value.loop,
      })
      this.sounds[key].sound = sound
      if (value.url) {
        sound.once('load', () => {
          this.sounds[key].loaded = true
          this.handleSoundLoaded()
        })
      } else {
        this.sounds[key].loaded = true
        this.handleSoundLoaded()
      }
    }
  }
  handleSoundLoaded = () => {
    const entries = Object.entries(this.sounds)
    let loadedCount = 0
    for (const [key, value] of Object.entries(this.sounds)) {
      if (value.loaded) loadedCount++
    }
    const progress = (loadedCount / entries[0].length)
    useLoaderStore.setState({ soundsProgress: progress * 100 })
  }
  fadeInBackgroundSound = () => {
    this.playSound('Enviroment', { fadeIn: 3000 })
  }
  playSound(
    soundName,
    settings
  ) {
    if (this.soundsEnabled) {
      const chosenSound = this.sounds[soundName].sound
      if (chosenSound) {
        !settings.fadeIn && chosenSound.play()
        if (settings.fadeIn) {
          if (!chosenSound.playing()) {
            chosenSound.play()
          }
          chosenSound.fade(0, 1, settings.fadeIn)
        }
      }
    }
  }

  toggleSound = () => {
    this.soundsEnabled = !this.soundsEnabled
    useStore.setState({ sound: this.soundsEnabled })
    if (this.soundsEnabled) {
      this.sounds['Enviroment'].sound.play()
    }else {
      for (const [key] of Object.entries(this.sounds)) {
        this.sounds[key].sound.stop()
      }
    }
  }

  fadeOutSounds = () => {
    
  }
}

export const audioManager = new AudioManager()
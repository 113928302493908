import { animated, useSpring } from "@react-spring/web";

export default function CircularProgressBar({
  size,
  width,
  fillColor = '#c1c1c1',
  progressColor = 'yellow',
  radius,
  percentage,
  resumeFadeOut,
  setReadyShowBtn,
  animate,
}) {

  const { transform, dashMainOffset } = animate
  const dashArray = radius * Math.PI * 2
  const [{dashOffset}] = useSpring(() => ({
    dashOffset: dashArray - (dashArray * percentage) / 100,
    onResolve: (p) => {
      percentage >= 100 && setTimeout(() => { setReadyShowBtn(true) }, 500)
    },
    config: {
      duration: 250
    }
  }), [percentage])

  return (
    <animated.svg
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      className='circular-progress-bar'
      style={{ transform }}
    >
      <animated.circle
        fill='none'
        stroke={fillColor}
        cx={size / 2}
        cy={size / 2}
        r={radius}
        strokeWidth={`${width}px`}
        style={{ strokeDasharray: dashArray, strokeDashoffset: dashMainOffset }}
      />
      <animated.circle
        stroke={progressColor}
        strokeLinecap='round'
        strokeLinejoin='round'
        fill='none'
        cx={size / 2}
        cy={size / 2}
        r={radius}
        strokeWidth={`${width}px`}
        transform={`rotate(-90 ${size / 2} ${size / 2})`}
        className='transition-all duration-500'
        style={{
          strokeDasharray: dashArray,
          strokeDashoffset: dashOffset
        }}
      />
    </animated.svg>
  )
}
import CircularProgressBar from './CircularProgressBar'
import { useEffect, useState } from 'react'
import { useSpring, animated } from "@react-spring/web";
// import useStore, { animationPageStatus } from '@/helpers/store'
// import Typography from '@/components/dom/ui/Typography'
// import Button from '@/components/dom/ui/Button'
// import { audioManager } from '@/helpers/AudioManger'
// import { classNames } from '@/helpers/classNames'
// import { usePageStore } from '@/store/store'

// import '../../src/styles/header.scss';
import '../../styles/loader.scss';
import { useLoaderStore, useStore } from '../../store'
import { audioManager } from '../../js/helpers/AudioManager';

const size = 200
const width = 4
const radius = (size - width) / 2
const dashArray = radius * Math.PI * 2

export default function Preloader() {
  const [loaderProgress, setLoaderProgress] = useState(0)

  const [animationEnd, setAnimationEnd] = useState(false)
  const [readyShowBtn, setReadyShowBtn] = useState(false)
//   const loaderProgress = useStore((state) => state.loaderProgress)
//   const isFirstEnter = useStore((state) => state.isFirstEnter)
  const loadingState = useLoaderStore((state) => state.showLoader)
  const progress3D = useLoaderStore((state) => state.progress3D)
  const soundsProgress = useLoaderStore((state) => state.soundsProgress)
  const [hidePreload, setHidePreload] = useState(false)


  // main animation controller
  const [
    {
      opacity,
      transform,
      transformLogo,
      opacitySlogan,
      dashMainOffset,
      opacityLogoPng,
      opacityLogoText,
    },
    api,
  ] = useSpring(() => ({
    from: {
      transformLogo: `translate(-50%,-50%) scale(1.5) rotate(0deg)`,
      opacitySlogan: 0,
      transform: `translateY(-100px)`,
      dashMainOffset: dashArray,
      opacityLogoPng: 0,
      opacityLogoText: 0,
      opacity: 1,
    },
    config: {
      duration: 250,
    },
  }))

  const startFadeOut = () => {
    setReadyShowBtn(false)
    // audioManager.playSound('Preload-Transition-Out')
    api.start({
      to: async (next) => {
        // transition out animation chain
        // hide track
        await next({
          dashMainOffset: dashArray,
          transform: 'translateY(0px)',
          delay: 500,
          onResolve: () => {
            // reverse loader progress animation
            // useStore.setState({ loaderProgress: 0 })
            setLoaderProgress(0)
            
          },
        })
        await next({
          opacityLogoPng: 0,
        })
        await next({
          opacity: 0,
          onStart: () => {
            useStore.setState({ start3DAnimation: true })
          },
          onResolve: () => {
            useLoaderStore.setState({ showLoader: false })
            setHidePreload(true);
          }
        })
      },
    })
  }

  const resumeFadeOut = () => {
    api.start({
      to: async (next) => {
        // logo and slogan animation
        await next({
          opacityLogoText: 0,
          opacitySlogan: 0,
          transformLogo: 'translate(-50%,-50%) scale(1) rotate(360deg)',
          opacityLogoPng: 0,
          delay: 250,
        })
        // bg animation
        await next({
          opacity: 0,
          onResolve: onFadeOutEnd,
        })
      },
    })
  }

  const onFadeOutEnd = () => {
    handleAnimationEnd('page fade in')
    // useStore.setState({ loading: true })
  }

  const startFadeIn = () => {
    // audioManager.playSound('Preload-Transition-In')
    api.start({
      to: async (next) => {
        // transition in animation chain
        // bg animation
        await next({
          opacity: 1,
          onStart: () => {
            setAnimationEnd(false)
          },
        })
        // track animation
        await next({
          dashMainOffset: 0,
          transform: 'translateY(0px)',
        })
        // logo png animation
        await next({
          transformLogo: 'translate(-50%,-50%) scale(1) rotate(360deg)',
          opacityLogoPng: 1,
        })
        // logo text animation
        await next({
          opacityLogoText: 1,
        })
        // slogan animation
        await next({
          opacitySlogan: 1,
          onResolve: onFadeInEnd,
        })
      },
    })
  }

  const onFadeInEnd = () => {
    setAnimationEnd(true)
    handleAnimationEnd('preloader faded')
  }

  useEffect(() => {
    loadingState && startFadeIn()
  }, [loadingState])

  const handleAnimationEnd = (status) => {
    // usePageStore.setState({ pageStatus: status })
  }

  // func to start transition out
  const handleStartFadeOut = () => {
    // if (isIOS) {
    if (typeof( DeviceMotionEvent ) !== "undefined") {
      //@ts-ignore
      if (typeof DeviceMotionEvent.requestPermission === 'function') {
        //@ts-ignore
        DeviceMotionEvent.requestPermission().then(permissionState => {
          startFadeOut()
          audioManager.fadeInBackgroundSound()
        })
        return;
      }
    } else {
    }
    // }
    startFadeOut()
    audioManager.fadeInBackgroundSound()
  }

  useEffect(() => {
    const progress = (progress3D + soundsProgress) / 2
    console.log("A: progress 123", progress, progress3D, soundsProgress);
    setLoaderProgress(progress)
    // if (progress3D === 100 && soundsProgress === 100) {
    //   // setReadyShowBtn(true)
    // }
  }, [progress3D, soundsProgress])

  return (
    <animated.div
      className='preloader'
      style={{ opacity: opacity, display: hidePreload ? 'none' : 'flex'}}
    >
      <div className='bg-image'>
        {/* <img
          src="../images/main-bg.jpg"
        /> */}
      </div>
      <div className='preloader-wrapper'>
        <div className='progress_bar-wrapper'>
          <div>
            <CircularProgressBar
              size={size}
              width={width}
              percentage={animationEnd ? loaderProgress : 0}
              radius={radius}
              resumeFadeOut={resumeFadeOut}
              setReadyShowBtn={setReadyShowBtn}
              animate={{
                transform,
                dashMainOffset,
              }}
            />
            <animated.div
              className='logo'
              style={{
                transform: transformLogo,
                opacity: opacityLogoPng,
              }}
            >
              <img
                  width='122' height='118'
                  className="header__logo_small"
                  src="../icons/logo-small.png"
                  alt="Logo"
                />
            </animated.div>
          </div>
        </div>

        {/* <animated.div
          className='flex flex-col'
          style={{ opacity: opacityLogoText }}
        >
          <Typography className='text-base tracking-widest text-center text-primary'>
            WEB
          </Typography>

          <div>
            <img
              width='200px'
              height='23px'
              src='/img/disruptors.webp'
              alt='Disruptors'
            />
          </div>
        </animated.div>
        <animated.div className='mt-3 mb-6 ' style={{ opacity: opacitySlogan }}>
          <Typography
            element='span'
            className='text-base font-light tracking-widest text-secondary'
          >
            ENGAGE | INTERACT | INSPIRE
          </Typography>
        </animated.div> */}
        <div
          className={
            'button-wrapper ' + (readyShowBtn  ? 'active' : '')
          }
        >
          {readyShowBtn ?
            <button
              onClick={handleStartFadeOut}
            >
              Begin 3D Experience
            </button> 
          : null}
        </div>
      </div>
    </animated.div>
  )
}

/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.3 frog_001.glb
*/

import React, { useRef, useEffect } from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'
import * as THREE from 'three'
import { useStore } from '../../../store'
import { useFrame, useThree } from '@react-three/fiber'
import { audioManager } from '../../../js/helpers/AudioManager'


export function Model(props) {
  const group = useRef()
  const { nodes, materials, animations } = useGLTF('/models/frog_001.glb')
  const { actions, mixer } = useAnimations(animations, props.modelRef)
  const isFrogJumped = useRef(false)
  const isAnimationFinished = useRef(false)

  useEffect(() => {
    actions['All Animations'].setLoop(THREE.LoopOnce).clampWhenFinished = true
    props.playAnimationFlag && actions['All Animations'].play()
    
  }, [props.playAnimationFlag])

  useEffect(() => {
    mixer.addEventListener('finished', function (e) {
      useStore.setState({ amimation3dFinished: true })
      setTimeout(() => {
        isAnimationFinished.current = true
      }, 100);
    })
    nodes['body1'].castShadow = true;

    // document.addEventListener('mousemove', (onMouseMove))
  }, [])
  const { mouse }  = useThree()
  const vec = new THREE.Vector3()
  useFrame(() => {
    if (!isFrogJumped.current && actions['All Animations'].time > 5.2) {
      isFrogJumped.current = true
      audioManager.playSound("Jump", {})
      setTimeout(() => {
        const flare = document.getElementById('flare')
        flare.addEventListener("animationend", (event) => {
          flare.removeEventListener('animationend', null)
          // flare.classList.remove("flare-animated");
        });
        flare.classList.add("flare-animated");
        setTimeout(() => {
          props.modelRef.current.visible = false;
        }, 1000);
        setTimeout(() => {
          useStore.setState({ startFlareAnimaition: true })
        });
      }, 3200);
    }
  })

  return (
    <group ref={props.modelRef} dispose={null}>
      <group>
        <primitive object={nodes._frog_rigging2Group}/>
        {/* <group name="group">
          <group name="pasted__Lily_Pad" position={[1.38, -0.01, -0.01]} rotation={[0, 0.96, 0.03]} scale={0.88}>
            <mesh name="pasted__polySurface294" geometry={nodes.pasted__polySurface294.geometry} material={materials.pasted__lambert21} />
          </group>
          <group name="pasted__box" position={[0, 0.16, 5.09]}>
            <mesh visible={false} name="pasted__Object001" geometry={nodes.pasted__Object001.geometry} material={materials.pasted__blinn2} position={[0.9, 0.2, 0.33]} rotation={[-Math.PI / 2, 0, 0]} scale={2.6} />
            <group ref={group}>
              <mesh visible={false} name="pasted__Object002" geometry={nodes.pasted__Object002.geometry} material={materials.pasted__blinn4} position={[0.9, -1.11, 0.33]} rotation={[-Math.PI / 2, 0, 0]} scale={2.6} />
            </group>
            <mesh ref={group} position={[1,1,0]}>
              <planeBufferGeometry args={[1,1,1]} />
            </mesh>
          </group>
          <group name="pasted__ripples">
            <mesh name="pasted__polySurface295" geometry={nodes.pasted__polySurface295.geometry} material={materials.pasted__lambert14} position={[0.84, -0.03, -0.43]} rotation={[0.02, 0.49, 0.02]} scale={0.48} />
            <mesh name="pasted__polySurface297" geometry={nodes.pasted__polySurface297.geometry} material={materials.pasted__lambert14} position={[0.83, -0.04, -0.39]} rotation={[0.02, 0.49, 0.02]} scale={0.51} />
            <mesh name="pasted__polySurface296" geometry={nodes.pasted__polySurface296.geometry} material={materials.pasted__lambert14} position={[0.83, -0.03, -0.37]} rotation={[0.02, 0.49, 0.02]} scale={0.52} />
          </group>
        </group> */}
      </group>
    </group>
  )
}

useGLTF.preload('/models/frog_001.glb')

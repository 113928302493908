import React from "react";

const Footer = () => {
  return (
    <>
      <footer className="footer">
        <div className="wrap">
          <div className="footer__left">
            <p className="copyright">
              Copyright © 2022 FoxFarm Soil & Fertilizer Co.
            </p>
          </div>
          <div className="footer__right">
            <div className="footer__menu">
              <a href="#">Terms of Use</a>
              <span className="footer__menu_line"></span>
              <a href="#">Privacy Policy</a>
              <span className="footer__menu_line"></span>
              <a href="#">CA Supply Chains Act</a>
              <span className="footer__menu_line"></span>
              <a href="#">Accessibility Statement</a>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
